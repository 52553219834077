import {
    MYACCOUNT_DASHBOARD,
    MYACCOUNT_INBOX,
    MYACCOUNT_MYPROPERTY,
    MYACCOUNT_CALENDAR,
    MYACCOUNT_PROFILE,
    FRONTEND_SITE_URL,
    WEBSITE_URL,
    MYACCOUNT_LIGHT
} from "./src/core/constants/urls";

import { createSearchUrl } from "./src/core/components/MyAccount/pages/properties/functions";

import CustomBedsIcon from './src/assets/icons/martyngerrard/bedrooms.inline.svg';
import CustomBathIcon from './src/assets/icons/martyngerrard/bathrooms.inline.svg';
import CustomRecIcon from './src/assets/icons/martyngerrard/receptions.inline.svg';
import CustomLogoIcon from './src/assets/icons/balgores/logo.inline.svg';

// Heading definitions h1 - h6 + line heights
const h1Size = "4.125rem", // 1rem === htmlFontSize
    h1LineHSize = 1.09, // lineHeight / fontSize
    h2Size = "3.125rem",
    h2LineHSize = 1.28,
    h3Size = "2.125rem",
    h3LineHSize = 1.14,
    h4Size = "2rem",
    h4LineHSize = 1.25,
    h5Size = "1.5rem",
    h5LineHSize = 1.33,
    h6Size = "1.25rem",
    h6LineHSize = 1.4;

// body size custom definitions
const bodyXSmallSize = "0.6875rem",
    lineHeightBXSmallSize = 1.63,
    bodySmallSize = "0.875rem",
    lineHeightBSmallSize = 1.43,
    bodyMediumSize = "1rem",
    lineHeightBMediumSize = 1.5,
    bodyLargeSize = "1.25rem",
    lineHeightBLargeSize = 1.44,
    bodyXLargeSize = "1.5rem",
    lineHeightBXLargeSize = 1.55;


// Custom grid column/container values
const gridItemPmobileValue = "1rem",
    gridItemPtabletValue = "2.5rem",
    gridItemPdesktopValue = "1.875rem";
//   gridItemPdesktopValue = "3.75rem";

const shadowKeyUmbraOpacity = 0.1;
const shadowKeyPenumbraOpacity = 0.1;
const shadowAmbientShadowOpacity = 0.1;
function createShadow(...px) {
    return [
        `${px[0]}px ${px[1]}px ${px[2]}px ${px[3]}px rgba(0,0,0,${shadowKeyUmbraOpacity})`,
        `${px[4]}px ${px[5]}px ${px[6]}px ${px[7]}px rgba(0,0,0,${shadowKeyPenumbraOpacity})`,
        `${px[8]}px ${px[9]}px ${px[10]}px ${px[11]}px rgba(0,0,0,${shadowAmbientShadowOpacity})`,
    ].join(',');
}

const propertyLink = (p) => {
    let sectorPath = p.sector || p.property_type || p.search_type;
     if (sectorPath) {
        sectorPath = sectorPath.replace(' ', '-');
        sectorPath = sectorPath === 'to-let' ? 'to-rent' : sectorPath
    }
    return `${FRONTEND_SITE_URL}/property-${sectorPath}/${p.slug}-${p.objectID || p.id}`
}

const resultLinkFun = (params) => {
    const basePathVal = {
        residential: 'properties',
        commercial: 'commercial',
        new_developments: 'new-homes'
    }
    return `${FRONTEND_SITE_URL}${createSearchUrl(params, {useStatus:true, minPricePrefix: 'over', maxPricePrefix: 'under', basePath : basePathVal, statusDelimiter:'-', areaPrefix:'', typePrefix:''})}`;
}

const themeName = 'balgores';

const termsSiteLink = `${FRONTEND_SITE_URL}/terms-and-conditions`;
const privacySiteLink = `${FRONTEND_SITE_URL}/privacy-policy`;
const defaultArea = 'essex';

export default {
    // Redefine the custom heading values so we have them available from the {theme} object
    h1: h1Size,
    h1LineH: h1LineHSize,
    h2: h2Size,
    h2LineH: h2LineHSize,
    h3: h3Size,
    h3LineH: h3LineHSize,
    h4: h4Size,
    h4LineH: h4LineHSize,
    h5: h5Size,
    h5LineH: h5LineHSize,
    h6: h6Size,
    h6LineH: h6LineHSize,

    // Redefine the custom body values so we have them available from the {theme} object
    bodyXSmall: bodyXSmallSize,
    lineHeightBXSmall: lineHeightBXSmallSize,
    bodySmall: bodySmallSize,
    lineHeightBSmall: lineHeightBSmallSize,
    bodyMedium: bodyMediumSize,
    lineHeightBMedium: lineHeightBMediumSize,
    bodyLarge: bodyLargeSize,
    lineHeightBLarge: lineHeightBLargeSize,
    bodyXLarge: bodyXLargeSize,
    lineHeightBXLarge: lineHeightBXLargeSize,

    // Redefine custom grid column/container values so we have them available from the {theme} object
    gridItemPmobile: `${gridItemPmobileValue}`,
    gridItemPtablet: `${gridItemPtabletValue}`,
    gridItemPdesktop: `${gridItemPdesktopValue}`,

    /**
     * MUI GLOBAL OVERRIDES OF STYLES
     */
    mixins: (theme) => ({
        toolbar: {
            minHeight: 60,
            [theme.breakpoints.up('md')]: {
                minHeight: 96
            },
        },
    }),
    palette: {
        primary: {
            // light: '#757ce8',
            main: "#B9AB84",
            dark: '#1B1434',
            heading: "#232125",
            title: "#1B1434",
            icon: "#B9AB84",
            contrastText: '#fff',
        },
        secondary: {
            // light: '#ff7961',
            main: "#B9AB84",
            dark: '#1B1434',
            contrastText: '#fff',
        },
        accent: {
            main: "#005480",
        },
        divider: "#D8D8D8",
        background: {
            paper: "#fff",
            default: "#fff",
        },
        grey: {
            // custom grey values
            grey1: "#3D3D3D",
            grey2: "#959595",
            grey3: "#DCDCDC",
            grey4: "#F6F6F5",
            grey5: "#FAFAFA",

        },
        text: {
            primary: "#1B1434",
            secondary : "#6A6A6A",
        },
    },
    shadows: [
        'none',
        createShadow(0, 2, 1, -1, 0, 1, 1, 0, 0, 1, 3, 0),
        createShadow(0, 0, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0), // elevation={2} is custom changed for module bgs
        createShadow(0, 3, 3, -2, 0, 3, 4, 0, 0, 1, 8, 0),
        createShadow(0, 2, 4, -1, 0, 4, 5, 0, 0, 1, 10, 0),
        createShadow(0, 3, 5, -1, 0, 5, 8, 0, 0, 1, 14, 0),
        createShadow(0, 3, 5, -1, 0, 6, 10, 0, 0, 1, 18, 0),
        createShadow(0, 4, 5, -2, 0, 7, 10, 1, 0, 2, 16, 1),
        createShadow(0, 5, 5, -3, 0, 8, 10, 1, 0, 3, 14, 2),
        createShadow(0, 5, 6, -3, 0, 9, 12, 1, 0, 3, 16, 2),
        createShadow(0, 6, 6, -3, 0, 10, 14, 1, 0, 4, 18, 3),
        createShadow(0, 6, 7, -4, 0, 11, 15, 1, 0, 4, 20, 3),
        createShadow(0, 7, 8, -4, 0, 12, 17, 2, 0, 5, 22, 4),
        createShadow(0, 7, 8, -4, 0, 13, 19, 2, 0, 5, 24, 4),
        createShadow(0, 7, 9, -4, 0, 14, 21, 2, 0, 5, 26, 4),
        createShadow(0, 8, 9, -5, 0, 15, 22, 2, 0, 6, 28, 5),
        createShadow(0, 8, 10, -5, 0, 16, 24, 2, 0, 6, 30, 5),
        createShadow(0, 8, 11, -5, 0, 17, 26, 2, 0, 6, 32, 5),
        createShadow(0, 9, 11, -5, 0, 18, 28, 2, 0, 7, 34, 6),
        createShadow(0, 9, 12, -6, 0, 19, 29, 2, 0, 7, 36, 6),
        createShadow(0, 10, 13, -6, 0, 20, 31, 3, 0, 8, 38, 7),
        createShadow(0, 10, 13, -6, 0, 21, 33, 3, 0, 8, 40, 7),
        createShadow(0, 10, 14, -6, 0, 22, 35, 3, 0, 8, 42, 7),
        createShadow(0, 11, 14, -7, 0, 23, 36, 3, 0, 9, 44, 8),
        createShadow(0, 11, 15, -7, 0, 24, 38, 3, 0, 9, 46, 8),
    ],
    shape: {
        borderRadius: 3,
    },
    spacing: factor => factor !== 'auto' ? `${0.50 * factor}rem` : factor, // this translates to 8px
    components: {
        MuiContainer: { // this <Container> has to be the parent of Mui Grid `container` item element
            styleOverrides: {
                maxWidthXl: {
                    paddingLeft: `${gridItemPmobileValue}`,
                    paddingRight: `${gridItemPmobileValue}`,
                    "@media screen and (min-width: 600px)": {
                        paddingLeft: `${gridItemPtabletValue}`,
                        paddingRight: `${gridItemPtabletValue}`,
                    },
                    "@media screen and (min-width: 1280px)": {
                        paddingLeft: `calc(${gridItemPdesktopValue} * 2)`,
                        paddingRight: `calc(${gridItemPdesktopValue} * 2)`,
                    },
                }
            },
        },
        MuiGrid: {
            styleOverrides: {
                // I've selected the spacing={1} to be a custom theme spacing grid for the use of the project
                // We change the horizontal spacings of items on different breakpoints and Remove vertical spacing
                // In the project each component should ideally be inside the grid item so the spacings are used consistently
                container: {
                    "&.MuiGrid-spacing-xs-1": {
                        width: `calc(100% + (${gridItemPmobileValue} * 2))`,
                        margin: `0 -${gridItemPmobileValue}`,
                        "@media screen and (min-width: 600px)": {
                            width: `calc(100% + (${gridItemPtabletValue} * 2))`,
                            margin: `0 -${gridItemPtabletValue}`,
                        },
                        "@media screen and (min-width: 1280px)": {
                            width: `calc(100% + (${gridItemPdesktopValue} * 2))`,
                            margin: `0 -${gridItemPdesktopValue}`,
                        },
                        "> .MuiGrid-item": {
                            padding: `0 ${gridItemPmobileValue}`,
                            "@media screen and (min-width: 600px)": {
                                padding: `0 ${gridItemPtabletValue}`,
                            },
                            "@media screen and (min-width: 1280px)": {
                                padding: `0 ${gridItemPdesktopValue}`,
                            },
                        }
                    },
                },
            },
        },
        MuiTypography: { // adding custom defined typography variants to use them as `props`
            variants: [
                {
                    props: { variant: 'bodyXSmall' },
                    style: {
                        fontSize: bodyXSmallSize,
                        letterSpacing: "0",
                        lineHeight: lineHeightBXSmallSize,
                    },
                },
                {
                    props: { variant: 'bodySmall' },
                    style: {
                        fontSize: bodySmallSize,
                        letterSpacing: "0",
                        lineHeight: lineHeightBSmallSize,
                    },
                },
                {
                    props: { variant: 'bodyMedium' },
                    style: {
                        fontSize: bodyMediumSize,
                        letterSpacing: "0",
                        lineHeight: lineHeightBMediumSize,
                    },
                },
                {
                    props: { variant: 'bodyLarge' },
                    style: {
                        fontSize: bodyLargeSize,
                        letterSpacing: "0",
                        lineHeight: lineHeightBLargeSize,
                    },
                },
                {
                    props: { variant: 'bodyXLarge' },
                    style: {
                        fontSize: bodyXLargeSize,
                        letterSpacing: "0",
                        lineHeight: lineHeightBXLargeSize,
                    },
                },
            ],
        },
        MuiButton: {
            defaultProps: {
                // variant: "contained",
            },
            styleOverrides: {
                root: {
                    borderRadius: "0px",
                    width: "100%",
                    textAlign: "center",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "24px",
                    "@media (min-width: 960px)": {
                        width: "auto",
                        // padding: "10px 26px",
                        // paddingTop:"12px"
                    },
                },
                containedPrimary: {
                    background: "#B9AB84",
                    boxShadow: "0px 4px 8px rgba(82, 97, 115, 0.2)",
                },
                containedSecondary: {
                    background: "#B9AB84",
                    boxShadow: "0px 4px 8px rgba(82, 97, 115, 0.2)",
                },
                sizeMedium: {
                    padding: "12px 26px",
                },
                sizeSmall: {
                    fontSize: "16px",
                    "@media (min-width: 991px)": {
                        fontSize: "14px",
                    },
                },
            },
            variants: [
                {
                  props: { variant: 'white' },
                  style: {
                    background: "#fff",
                    boxShadow: "none",
                    fontWeight: "bold",
                    "&:hover": {
                        background: "linear-gradient(225deg, #1663B3 0%, #217698 100%)",
                        color: "#fff",
                    },
                  },
                },
            ],
        },
        MuiPickersCalendar: {
            styleOverrides: {
                weekDayLabel: {
                    "fontSize": bodySmallSize,
                },
            },
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    "fontSize": bodySmallSize,
                },
            },
        },
        MuiClock: {
            styleOverrides: {
                root: {
                    "position": "relative", // Fix to position AM/PM inside the clock area
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                size: "small",
            },
        },
        MuiSelect: {
            defaultProps: {
                size: "small",
                variant: "outlined",
            },
        },
        MuiFormControl: {
            defaultProps: {
                size: "small",
                variant: "outlined",
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    "backgroundColor": "#005480",
                    "padding": "12px 16px",
                },
                arrow: {
                    "&::before": {
                        "backgroundColor": "#005480",
                    },
                },
            },
        },
        MuiLink: {
            defaultProps: {
                underline: 'hover',
            },
        },
        MuiIconButton: {
            defaultProps: {
                size: 'large',
            },
        },
    },
    typography: {
        htmlFontSize: 16,
        // fontFamily: 'Switzer', // for custom google font you need to include the <link> to the font in the header of the page, this is not done with MUI
        fontFamily: '"Switzer"',

        fontWeightBold: 700,
        h1: {
            fontFamily: '"ITCKabelStd"',
            fontSize: h1Size,
            fontWeight: 400,
            letterSpacing: "-0.01em",
            lineHeight: h1LineHSize, // lineHeight / fontSize
        },
        h2: {
            fontFamily: '"ITCKabelStd"',
            fontSize: h2Size,
            fontWeight: 400,
            letterSpacing: "-0.01em",
            lineHeight: h2LineHSize,
        },
        h3: {
            fontFamily: '"ITCKabelStd"',
            fontSize: h3Size,
            fontWeight: 400,
            letterSpacing: "-0.01em",
            lineHeight: h3LineHSize,
        },
        h4: {
            fontFamily: '"ITCKabelStd"',
            fontSize: h4Size,
            fontWeight: 400,
            letterSpacing: "-0.01em",
            lineHeight: h4LineHSize,
        },
        h5: {
            fontFamily: '"ITCKabelStd"',
            fontSize: h5Size,
            fontWeight: 400,
            letterSpacing: "-0.01em",
            lineHeight: h5LineHSize,
        },
        h6: {
            fontFamily: 'ITCKabelStd',
            fontSize: h6Size,
            fontWeight: 600,
            letterSpacing: "-0.01em",
            lineHeight: h6LineHSize,
        },
        body1: {
            fontSize: "1.125rem",
            letterSpacing: "-0.01em",
            lineHeight: 1.44,
        },
        caption: {
            fontSize: "0.6875rem",
        },
        button: {
            fontFamily: 'Switzer',
            letterSpacing: "0.03em",
        },
    },

    // Defining global google fonts to be added in the <head>
    // googleFont: "https://fonts.cdnfonts.com/css/tt-commons",

    primaryFontFamily: '"ITCKabelStd"',
    secondaryFontFamily: '"Switzer"',
    tertiaryFontFamily: '"GillSansNova"',

    // Global CSS implemneted by StarberryRootThemeProvider.
    "sx": {
        "@global":{
            "iframe": {
                "border": "none",
            },
            "body.floatingContainer-toolbar main": { // add additional spacing on bottom if we have floating toolbars
                "@media (max-width: 959px)": {
                    "paddingBottom": "10rem",
                },
            },
            "@font-face": [
                {
                    "fontFamily": "Switzer",
                    "src": "url('/fonts/Switzer/switzer-regular.woff2') format('woff2'), url('/fonts/ModernEra/switzer-regular.woff') format('woff')",
                    // "fontWeight": 400,
                    "fontStyle": "normal",
                },
                {
                    "fontFamily": "ITCKabelStd",
                    "src": "url('/fonts/ITCKabelStd/font.woff2') format('woff2'), url('/fonts/ITCKabelStd/font.woff') format('woff')",
                    // "fontWeight": 600,
                    "fontStyle": "normal",
                },
                {
                    "fontFamily": "GillSansNova",
                    "src": "url('/fonts/GillSansNova/gill-sans-nova.woff2') format('woff2'), url('/fonts/GillSansNova/gill-sans-nova.woff') format('woff')",
                    // "fontWeight": 600,
                    "fontStyle": "normal",
                }
            ],
        },
    },

    /**
     * END MUI GLOBAL OVERRIDES OF STYLES
     */


    /**
    * Starberry custom theme variables
    */

    // Sidebar drawer navigation
    "myAccountSetDrawerWidth": 340,

    // Main content area paddings
    "myAccountContentPTmobile": 2.25, // top spacing for the content container
    "myAccountContentPBmobile": 2.25, // bottom spacing for the content container

    "myAccountContentPTtablet": 4, // top spacing for the content container
    "myAccountContentPBtablet": 4, // bottom spacing for the content container

    "myAccountContentPTDesktop": 6, // top spacing for the content container
    "myAccountContentPBDesktop": 6, // bottom spacing for the content container

    // module border radius if its different then MUI border radius
    "moduleBorderRadius": "14px",
    // Global sidebar module padding
    "modulePadding": theme => theme.spacing(4, 5),
    "moduleSpacing": theme => theme.spacing(2.6),
    "moduleSpacingSm": theme => theme.spacing(6),
    "moduleSpacingMd": theme => theme.spacing(6),
    "moduleSpacingLg": theme => theme.spacing(7),
    "moduleSpacingXl": theme => theme.spacing(7),

    /**
    * End Starberry custom theme variables
    */

    // The name of the theme.  This is used for naming elements in the
    // site.
    "name": themeName,

    // This is the theme name that helps to load theme specific items
    "themeName": themeName,

    // Site terms & privacy link
    "termsSiteLink": termsSiteLink,
    "privacySiteLink": privacySiteLink,

    // The company name, which can be used for the copyright message, and
    // anywhere else it might be needed.
    "companyName": "Balgores Estate Agents",

    // The copyright message of the site, for the footer.
    "copyright": theme => `© ${theme.year}, ${theme.companyName}`,

    // Simple abstraction to make the copyright message above a little
    // easier to read.
    "year": `${(new Date()).getFullYear()}`,

    // Used by <Breadcrumbs>
    "breadcrumbSeparator": "… ",

    // Social media links used by <FooterSocial>
    "social": {
        "Instagram": "https://www.instagram.com/starberrylife/",
        "Twitter": "https://twitter.com/starberry",
        "YouTube": "https://www.youtube.com/channel/UCAPHSlQISzaD-384O__9QhQ",
        "LinkedIn": "https://www.linkedin.com/company/starberry"
    },

    "images": {
        "default": { method: "img" },
        "propertyresult": {
            "method": "img",
            "sx": {
                "& img": {
                    "width": "100%",
                    "height": "auto",
                    "transform": "scale(1)",
                    "transition": "transform .2s ease-out",
                },
                "&:hover": {
                    "transform": "scale(1.3)",
                }
            }
        }
    },


    "logo": `
        <svg width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M115.816 4.20154L19.3821 341.041C18.3449 410.692 92.5026 429.745 92.5026 429.745L381.806 507.776L491.994 115.058L115.816 4.20154Z" fill="url(#paint0_linear_4534_36585)"/>
        <path d="M115.816 4.20154L19.3821 341.041C18.3449 410.692 92.5026 429.745 92.5026 429.745L381.806 507.776L491.994 115.058L115.816 4.20154Z" stroke="#B9AB84" stroke-width="3" stroke-miterlimit="10"/>
        <path d="M87.3845 341.513L90.8793 330.279C115.681 337.896 126.481 333.02 133.922 309.092L185.104 144.402C192.545 120.473 186.299 110.318 161.61 102.701L165.105 91.4671L289.61 129.887C377.093 156.826 388.029 189.764 378.446 220.186C367.511 255.393 326.407 260.628 294.661 257.64L294.435 258.314C330.173 277.592 364.918 303.655 350.578 349.804C338.042 390.134 300.365 407.344 233.49 386.696L87.3845 341.513ZM193.582 315.473C184.45 344.861 179.715 357.645 215.047 368.542C248.665 378.922 278.788 370.227 292.496 326.145C301.944 295.723 298.652 268.874 235.88 249.484L215.994 243.351L193.582 315.473ZM240.525 233.33C283.748 246.653 307.4 238.228 318.448 202.684C329.496 167.139 312.202 149.075 279.261 138.919C271.37 136.47 260.434 134.224 253.242 137.998C247.199 141.75 245.305 149.03 243.388 155.186L220.977 227.376L240.525 233.33Z" fill="#0D0434"/>
        <path d="M85.1296 337.02L88.6244 325.786C113.426 333.402 124.226 328.527 131.667 304.598L182.849 139.908C190.29 115.98 184.044 105.824 159.355 98.2075L162.85 86.9735L287.355 125.394C374.838 152.333 385.774 185.271 376.191 215.693C365.256 250.9 324.152 256.135 292.406 253.147L292.18 253.821C327.918 273.098 362.663 299.161 348.323 345.31C335.787 385.64 298.11 402.851 231.235 382.203L85.1296 337.02ZM191.327 310.979C182.195 340.367 177.46 353.152 212.792 364.048C246.41 374.429 276.533 365.734 290.241 321.651C299.689 291.23 296.397 264.381 233.625 244.991L213.739 238.857L191.327 310.979ZM238.27 228.836C281.493 242.16 305.145 233.734 316.193 198.19C327.241 162.646 309.948 144.581 277.006 134.426C269.115 131.977 258.179 129.73 250.987 133.505C244.944 137.257 243.05 144.536 241.134 150.693L218.722 222.882L238.27 228.836Z" fill="white" stroke="#B9AB84" stroke-width="1.5" stroke-miterlimit="10"/>
        <defs>
        <linearGradient id="paint0_linear_4534_36585" x1="91.984" y1="442.282" x2="417.591" y2="52.862" gradientUnits="userSpaceOnUse">
        <stop offset="0.07" stop-color="#1D1848"/>
        <stop offset="0.11" stop-color="#1C225D"/>
        <stop offset="0.15" stop-color="#1C2B71"/>
        <stop offset="0.2" stop-color="#1B307D"/>
        <stop offset="0.25" stop-color="#1B3281"/>
        <stop offset="0.31" stop-color="#182B6E"/>
        <stop offset="0.38" stop-color="#15265F"/>
        <stop offset="0.44" stop-color="#14245A"/>
        <stop offset="0.49" stop-color="#13275F"/>
        <stop offset="0.55" stop-color="#0E316D"/>
        <stop offset="0.61" stop-color="#074184"/>
        <stop offset="0.66" stop-color="#00529C"/>
        <stop offset="0.87" stop-color="#211D70"/>
        </linearGradient>
        </defs>
        </svg>

    `,
    "logoSecondary": `
        <svg width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M115.816 4.20154L19.3821 341.041C18.3449 410.692 92.5026 429.745 92.5026 429.745L381.806 507.776L491.994 115.058L115.816 4.20154Z" fill="url(#paint0_linear_4534_36585)"/>
        <path d="M115.816 4.20154L19.3821 341.041C18.3449 410.692 92.5026 429.745 92.5026 429.745L381.806 507.776L491.994 115.058L115.816 4.20154Z" stroke="#B9AB84" stroke-width="3" stroke-miterlimit="10"/>
        <path d="M87.3845 341.513L90.8793 330.279C115.681 337.896 126.481 333.02 133.922 309.092L185.104 144.402C192.545 120.473 186.299 110.318 161.61 102.701L165.105 91.4671L289.61 129.887C377.093 156.826 388.029 189.764 378.446 220.186C367.511 255.393 326.407 260.628 294.661 257.64L294.435 258.314C330.173 277.592 364.918 303.655 350.578 349.804C338.042 390.134 300.365 407.344 233.49 386.696L87.3845 341.513ZM193.582 315.473C184.45 344.861 179.715 357.645 215.047 368.542C248.665 378.922 278.788 370.227 292.496 326.145C301.944 295.723 298.652 268.874 235.88 249.484L215.994 243.351L193.582 315.473ZM240.525 233.33C283.748 246.653 307.4 238.228 318.448 202.684C329.496 167.139 312.202 149.075 279.261 138.919C271.37 136.47 260.434 134.224 253.242 137.998C247.199 141.75 245.305 149.03 243.388 155.186L220.977 227.376L240.525 233.33Z" fill="#0D0434"/>
        <path d="M85.1296 337.02L88.6244 325.786C113.426 333.402 124.226 328.527 131.667 304.598L182.849 139.908C190.29 115.98 184.044 105.824 159.355 98.2075L162.85 86.9735L287.355 125.394C374.838 152.333 385.774 185.271 376.191 215.693C365.256 250.9 324.152 256.135 292.406 253.147L292.18 253.821C327.918 273.098 362.663 299.161 348.323 345.31C335.787 385.64 298.11 402.851 231.235 382.203L85.1296 337.02ZM191.327 310.979C182.195 340.367 177.46 353.152 212.792 364.048C246.41 374.429 276.533 365.734 290.241 321.651C299.689 291.23 296.397 264.381 233.625 244.991L213.739 238.857L191.327 310.979ZM238.27 228.836C281.493 242.16 305.145 233.734 316.193 198.19C327.241 162.646 309.948 144.581 277.006 134.426C269.115 131.977 258.179 129.73 250.987 133.505C244.944 137.257 243.05 144.536 241.134 150.693L218.722 222.882L238.27 228.836Z" fill="white" stroke="#B9AB84" stroke-width="1.5" stroke-miterlimit="10"/>
        <defs>
        <linearGradient id="paint0_linear_4534_36585" x1="91.984" y1="442.282" x2="417.591" y2="52.862" gradientUnits="userSpaceOnUse">
        <stop offset="0.07" stop-color="#1D1848"/>
        <stop offset="0.11" stop-color="#1C225D"/>
        <stop offset="0.15" stop-color="#1C2B71"/>
        <stop offset="0.2" stop-color="#1B307D"/>
        <stop offset="0.25" stop-color="#1B3281"/>
        <stop offset="0.31" stop-color="#182B6E"/>
        <stop offset="0.38" stop-color="#15265F"/>
        <stop offset="0.44" stop-color="#14245A"/>
        <stop offset="0.49" stop-color="#13275F"/>
        <stop offset="0.55" stop-color="#0E316D"/>
        <stop offset="0.61" stop-color="#074184"/>
        <stop offset="0.66" stop-color="#00529C"/>
        <stop offset="0.87" stop-color="#211D70"/>
        </linearGradient>
        </defs>
        </svg>
    `,

    // Subthemes.  The values within a subtheme will overlay a copy of the
    // parent theme.  Subthemes can themselves contain subthemes.
    "subthemes": {
        "siteby": {
            "tweak": 0.075, // Align baseline of font so SiteByStarberry lines up.  This needs to be kludged for each particular site. :(
        },
        "content": {
            "props": {
                "direction": "column",
            },
            "contained": true,
        },
        "header": {
            "contained": true,

            "props": {
                "direction": "row",
                "alignItems": "middle",
                "justify": "space-between",
                "height": "5rem",
                "paddingTop": "1rem"
            },

            "sx": {
                "root": {
                    "backgroundColor": "black"
                }
            },

            "subthemes": {
                "logo": {
                    "props": {
                        "width": "10rem",
                        "justify": "left",
                        "sx": {

                        }
                    }
                },

                "menu": {
                    "type": "burger",
                    "props": {
                        "direction": "row",
                        "alignItems": "left",
                        "justify": "space-around",
                    },

                    "anchorOrigin": { "horizontal": "center" },
                    "transformOrigin": { "horizontal": "center" },

                    "sx": {
                        "root": {
                            "& .HeaderMenuContainer": {
                                "display": "flex",
                                "flex-direction": "row",
                            },
                            "& .HeaderMenuLevel0.HeaderMenuLink": {
                                // "flex-direction": "row"
                            },
                            "& .HeaderMenuLevel0.HeaderMenuLink span": {
                                "font-weight": 600
                            },
                            "& .HeaderMenuLevel1 span": {
                            }
                        }
                    },

                    "menus": {
                        "Home": "/",
                        "About Us": "/about-us",
                        "Careers": "/careers",
                        "‍Work": "/our-work",
                        "Stories": "/stories",
                        "Contact Us": "/contact-us",
                        "Who We Work With": {
                            "Estate Agents": "/who-we-work-with/estate-agents",
                            "Property Developers": "/who-we-work-with/property-developers",
                            "Proptech": "/who-we-work-with/proptech",
                            "Finance": "/who-we-work-with/finance",
                            "Solicitors & Lawyers": "/who-we-work-with/legal",
                            "Architects": "/who-we-work-with/architects",
                            "Interior Design": "/who-we-work-with/interior-design"
                        },
                        "What We Do": {
                            "Branding": "/what-we-do/branding",
                            "Website Design": "/what-we-do/website-design",
                            "Website Development": "/what-we-do/website-development",
                            "Content Marketing": "/what-we-do/content-marketing",
                            "Video": "/what-we-do/video",
                            "Social Media": "/what-we-do/social-media",
                            "SEO": "/what-we-do/seo",
                            "Digital Advertising": "/what-we-do/digital-advertising",
                            "Email Marketing": "/what-we-do/email-marketing"
                        }
                    }
                }
            }
        },
        "footer": {
            "contained": "outer",
            "props": {
                "direction": "column",
            },

            "sx": {
                "root": {
                    "& a": {
                        "color": "currentColor"
                    },
                    "& ul": {
                        "margin": 0,
                        "padding": 0,
                        "list-style": "none",
                        "display": "block"
                    }
                }
            },

            "titleVariant": "button",
            "colors": {
                "background": "primary",
                "text": "#fff"
            },
            "menu": {
                "menus": {
                    "Menu": {
                        "Home": "/",
                        "About Us": "/about-us",
                        "Careers": "/careers",
                        "‍Work": "/our-work",
                        "Stories": "/stories",
                        "Contact Us": "/contact-us"
                    },
                    "Who We Work With": {
                        "Estate Agents": "/who-we-work-with/estate-agents",
                        "Property Developers": "/who-we-work-with/property-developers",
                        "Proptech": "/who-we-work-with/proptech",
                        "Finance": "/who-we-work-with/finance",
                        "Solicitors & Lawyers": "/who-we-work-with/legal",
                        "Architects": "/who-we-work-with/architects",
                        "Interior Design": "/who-we-work-with/interior-design"
                    },
                    "What We Do": {
                        "Branding": "/what-we-do/branding",
                        "Website Design": "/what-we-do/website-design",
                        "Website Development": "/what-we-do/website-development",
                        "Content Marketing": "/what-we-do/content-marketing",
                        "Video": "/what-we-do/video",
                        "Social Media": "/what-we-do/social-media",
                        "SEO": "/what-we-do/seo",
                        "Digital Advertising": "/what-we-do/digital-advertising",
                        "Email Marketing": "/what-we-do/email-marketing"
                    },
                    "Follow Us": {
                        "Instagram": "https://www.instagram.com/starberrylife/",
                        "Twitter": "https://twitter.com/starberry",
                        "YouTube": "https://www.youtube.com/channel/UCAPHSlQISzaD-384O__9QhQ",
                        "LinkedIn": "https://www.linkedin.com/company/starberry"
                    }
                }
            }
        },
        "menuRouterHomePage": { // Specify which homepage version layout we want to use
            "props": {
                "pageVariant": MYACCOUNT_LIGHT ? "MyProperty" : "Dashboard"
            },
        },
        // "myAccount": {
        // "subthemes": {
        "bookingWidget":{ // options: src/core/components/MyAccount/property/components/iframeBookViewing/defaults.js
        },
        "imageBox": { // options: src/components/MyAccount/imageBox/defaults.js
        },
        "videoText": { // options: src/components/MyAccount/modules/videoText/defaults.js
        },
        "iconBox": { // options: src/components/MyAccount/iconBox/defaults.js
        },
        "static2column": { // options: src/components/MyAccount/modules/grid/defaults.js
        },
        "bgIcon": { // options: src/components/MyAccount/modules/bgIcon/defaults.js
        },
        "socialShare": { // options: src/components/MyAccount/socialShare/defaults.js
        },
        "backButton": { // options: src/components/MyAccount/backButton/defaults.js
        },
        "projectIcons": { // options: src/components/MyAccount/icons/defaults.js
            "props": {
                // "propBedsIcon": CustomBedsIcon,
                // "propBathIcon": CustomBathIcon,
                // "propRecIcon": CustomRecIcon,
                "logoIcon": CustomLogoIcon,
            },
        },
        "sidebarDrawer": { // options: src/components/MyAccount/SidebarDrawerDefaults.js
            "props": {
                "menus": [
                    {
                        "hide": false,
                        "name": "Back to Website",
                        "icon": {
                            "name": "backToWebsiteIcon",
                            "props": {
                                "color": "primary",
                                "fontSize": "small",
                            },
                        },
                        "link": `${WEBSITE_URL}`,
                        "newtab": true,
                        "menutype": "external",
                    },
                    {
                        "hide": true,
                        "name": "Dashboard",
                        "icon": {
                            "name": "dashboardIcon", // add the name of the icon from MUI, we've preset some of the icons that can be selected not all
                            "props": {
                                "color": "primary",
                                "fontSize": "small",
                            },
                        },
                        "link": `${MYACCOUNT_DASHBOARD}`,
                    },
                    {
                        "hide": false,
                        "name": "Dashboard",
                        "icon": {
                            "name": "dashboardIcon",
                            "props": {
                                "color": "primary",
                                "fontSize": "small",
                            },
                        },
                        "link": `${MYACCOUNT_MYPROPERTY}`,
                    },
                    {
                        "hide": true,
                        "name": "Inbox",
                        "icon": {
                            "name": "inboxIcon",
                        },
                        "link": `${MYACCOUNT_INBOX}`,
                    },
                    {
                        "hide": true,
                        "name": "Calendar",
                        "icon": {
                            "name": "calendarIcon",
                        },
                        "link": `${MYACCOUNT_CALENDAR}`,
                    },
                    {
                        "hide": false,
                        "name": "Profile",
                        "icon": {
                            "name": "profileIcon",
                        },
                        "link": `${MYACCOUNT_PROFILE}`,
                    },
                    {
                        "hide": true,
                        "name": "Settings",
                        "icon": {
                            "name": "settingsIcon",
                        },
                        "link": "#",
                    },
                    {
                        "hide": false,
                        "name": "Log out",
                        "icon": {
                            "name": "logoutIcon",
                        },
                        "link": "#",
                        "funcName": "logout",
                    },
                ],
            },
            "sx": (theme) => ({
                "& .sidebarDrawer-drawerCommon-root": {
                    "display": 'flex',
                    "minHeight": "100vh",
                },
                "& .sidebarDrawer-drawerCommon-drawer": {
                    [theme.breakpoints.up('md')]: {
                        "width": `${theme.myAccountSetDrawerWidth}px`,
                        "flexShrink": 0,
                    },
                },
                "& .sidebarDrawer-drawerCommon-drawerPaper": {
                    "width": `${theme.myAccountSetDrawerWidth}px`,
                    "backgroundColor": "#1B1434",
                    "color": theme.palette.primary.contrastText,
                    "padding": "50px 50px 0 50px",
                },
                "& .sidebarDrawer-menuList": {
                    "marginLeft": theme.spacing(3),
                    "padding": theme.spacing(0),

                    // [theme.breakpoints.up('lg')]: {
                    //     "padding": theme.spacing(0, 8),
                    // },
                    "& .sidebarDrawer-menuListItem": {
                        "padding": theme.spacing(1.5, 0),
                        "opacity": 0.4,
                        "background": "none",
                        "&.Mui-selected": {
                            "opacity": 1,
                            "background": "none",
                        },
                        "&.Mui-selected:hover": {
                            "opacity": 1,
                            "background": "none",
                        },
                        "&:hover": {
                            "opacity": 1,
                            "background": "none",
                        },
                        "& .sidebarDrawer-menuListItemText": {
                        },
                        "& .sidebarDrawer-menuListItemTextPrimary": {
                            "fontFamily": theme.secondaryFontFamily,
                            "marginTop" : "4px",
                        },
                    },
                    "& .sidebarDrawer-menuListItemIcon": {
                        "minWidth": "40px",
                        "color": theme.palette.primary.contrastText,
                    },
                },
            }),
            "subthemes": {
                "logo": {
                    "sx": (theme) => ({
                        "margin": theme.spacing(4, "auto", 2, "auto"),
                        "width": "94px",
                        "height": "auto",
                        [theme.breakpoints.up('md')]: {
                            // "width": "94px",
                            "margin": theme.spacing(5, "auto", 9, "auto"),
                        },
                    }),
                },
            },
        },
        "appBar": { // options: src/components/MyAccount/AppBarDefaults.js
            "props": {
                "burgerButton": {
                    "color": "primary",
                    "aria-label": "open drawer",
                    "edge": "start",
                    "disableRipple": true,
                    "sx": {
                        "py": 0,
                        "marginRight": "0!important",
                        "&:hover": {
                            "background": "transparent",
                        },
                        "& svg": {
                            "width": "33px",
                            "height": "24px",
                        },
                    },
                },
                "menusBottomFixed": [
                    {
                        "id": 1,
                        "hide": false,
                        "name": "Dashboard",
                        "icon": {
                            "name": "dashboardIcon", // add the name of the icon from MUI, we've preset some of the icons that can be selected not all
                            "props": {
                                "color": "primary",
                                "fontSize": "small",
                            },
                        },
                        "link": `${MYACCOUNT_MYPROPERTY}`,
                    },
                    {
                        "id": 2,
                        "hide": true,
                        "name": "Alert",
                        "icon": {
                            "name": "inboxIcon",
                        },
                        "link": `${MYACCOUNT_MYPROPERTY}`,
                    },
                    {
                        "id": 3,
                        "hide": true,
                        "name": "Calendar",
                        "icon": {
                            "name": "calendarIcon",
                        },
                        "link": `${MYACCOUNT_CALENDAR}`,
                    },
                    {
                        "id": 4,
                        "hide": true,
                        "name": "Search",
                        "icon": {
                            "name": "searchIcon",
                        },
                        "link": `${MYACCOUNT_CALENDAR}`,
                    },
                    {
                        "id": 5,
                        "hide": false,
                        "name": "Pofile",
                        "icon": {
                            "name": "profileIcon",
                        },
                        "link": `${MYACCOUNT_PROFILE}`,
                    },
                    {
                        "id": 6,
                        "hide": false,
                        "name": "Menu",
                        "icon": {
                            "name": "mobileBurgerIcon",
                        },
                        "link": "#",
                        "funcName": "menu",
                    },
                ]
            },
            "sx": (theme) => ({
                "& .appBar": {
                    "boxShadow": "none",
                    "borderBottom": `1px solid ${theme.palette.divider}`,
                    [theme.breakpoints.up('md')]: {
                        "width": `calc(100% - ${theme.myAccountSetDrawerWidth}px)`,
                        "marginLeft": `${theme.myAccountSetDrawerWidth}px`,
                        // "marginRight": `${theme.myAccountSetDrawerWidth}px`, // Enable this if Drawer is positioned right
                    },
                },
                "& .appBar-grow": {
                    [theme.breakpoints.up("md")]: {
                        "flexGrow": 1,
                    }
                },
                "& .appBar-sectionDesktop": {
                    "display": "none",
                    [theme.breakpoints.up("md")]: {
                        "display": "flex",
                        "alignItems": "center",
                    },
                },
                "& .appBar-sectionMobile": {
                    "display": "flex",
                    [theme.breakpoints.up("md")]: {
                        "display": "none",
                    },
                },
                "& .appBar-burgerButton": {
                    "marginRight": theme.spacing(2),
                    [theme.breakpoints.up('md')]: {
                        "display": "none",
                    },
                },
                "& .appBar-appBarTitle": {
                    "marginLeft": "auto",
                    "marginRight": "auto",
                    [theme.breakpoints.up("md")]: {
                        "display": "none",
                    },
                },
                "& .appBar-menuListItemMobile": {
                    "justifyContent": "center",
                    "opacity": 0.4,
                    "background": "none",
                    "&.Mui-selected": {
                        "opacity": 1,
                        "background": "none",
                    },
                    "& .appBar-menuListItemMobile-divider": {
                        "borderColor": "black",
                    },
                },
                "& .appBar-menuListItemIconMobile": {
                    "color": theme.palette.primary.contrastText,
                    "minWidth": "inherit",
                },
                "& .appBar-userDropdown": {
                    "fontFamily": theme.secondaryFontFamily,
                    "margin": theme.spacing(0, 3.25),
                    "paddingLeft": theme.spacing(1),
                    "paddingRight": theme.spacing(1),
                    "color": theme.palette.primary.dark,
                    "textTransform": "capitalize",
                    "&:hover": {
                        "background": "none",
                    },
                    "& .appBar-label": {
                        // "textTransform": "capitalize",
                        "fontSize": theme.bodyMediumSize,
                        "fontWeight": theme.typography.fontWeightBold,
                        "color": theme.palette.primary.dark,
                    },
                    "& .appBar-text": {
                        "&:hover": {
                            "backgroundColor": "transparent",
                            "color": theme.palette.secondary.main,
                        },
                    },
                },
                "& .appBar-appBarMobile": {
                    "boxShadow": "none",
                    "backgroundColor": theme.palette.primary.dark,
                    "border": "none",
                    "width": "100%",
                    "top": 'auto',
                    "bottom": 0,
                },
                "& .appBar-userAvatar": {
                    "textTransform": "uppercase",
                },
            }),

        },
        "customSidebarDrawer": { // options: src/components/MyAccount/customSidebarDrawer/defaults.js
            "subthemes": {
                "menuListIconsTabs": {
                    "sx": (theme) => ({
                        "& .menuListIconsTabs": {
                            "padding": 0,
                            "margin": 0,
                        },
                        "& .menuListIconsTabs-vertical": {
                        },
                        "& .menuListIconsTabs-indicator": {
                            "display": "none",
                        },
                        "& .menuListIconsTabs-tab": {
                            "fontWeight": "bold",
                            "textTransform": "none",
                            "minHeight": "58px",
                            "borderBottom": `1px solid ${theme.palette.divider}`,
                            "paddingTop": theme.spacing(1.5),
                            "paddingBottom": theme.spacing(1.5),
                            "paddingLeft": theme.gridItemPmobile,
                            "paddingRight": theme.gridItemPmobile,
                            "opacity": 1,
                            [theme.breakpoints.up('sm')]: {
                                "fontSize": theme.bodyMedium,
                                "lineHeight": theme.lineHeightBMedium,
                                "paddingLeft": theme.gridItemPtablet,
                                "paddingRight": theme.gridItemPtablet,
                            },
                            [theme.breakpoints.up('md')]: {
                                "padding": theme.spacing(1.5, 0),
                            },
                            "&.menuListIconsTabs-tab-wrapper": {
                                "display": "flex",
                                "flexDirection": "row",
                                "alignItems": "center",
                                "justifyContent": "flex-start",
                            },
                        },
                        "& .menuListIconsTabs-tab-labelIcon svg": {
                            "color": theme.palette.text.primary,
                            "marginBottom": 0,
                            "marginRight": theme.spacing(2.25),
                        }
                    })
                },
            },
        },
        "globalComponentLayout": {
        },
        "globalDropdownActions": { // options: src/components/MyAccount/property/savedSearch/defaultsActions.js
            "props": {
                "dropdownActionsIcon": {
                    // "fontSize": "small",
                },
            },
            "sx": (theme) => ({
                "& .globalDropdownActions-dropdownActions": {
                    "fontSize": theme.bodyMedium,
                    "alignItems" : "center",
                    "& p":{
                        "marginTop": "3px",
                    }
                },
                "& .globalDropdownActions-dropdownActionsIcon": {
                    "minWidth": "34px",
                    "color": theme.palette.text.primary,
                },
            }),
        },
        "globalPageTitle": { // options: src/components/MyAccount/pageTitle/defaults.js
            "props": {
                "pageTitleProps": {
                    "component": "h1",
                    "variant": "h3",
                },
            },
            "sx": (theme) => ({
                "display": "none",
                "fontWeight": "normal",
                [theme.breakpoints.up('md')]: {
                    "display": "block",
                    "marginBottom": `${theme.spacing(4)} !important`,
                },
            }),
        },
        "globalForm": { // options: src/components/MyAccount/forms/defaults.js
        },
        "mainContent": { // options: src/components/MyAccount/ContentDefaults.js
        },
        "alertComponent": { // options: src/components/MyAccount/alert/defaults.js
        },
        "welcomeCard": { // options: src/components/MyAccount/user/welcome/defaults.js
        },
        "circularProgress": { // options: src/components/MyAccount/progress/defaults.js
        },
        "noActions": { // options: src/components/MyAccount/progress/NoActionsDefaults.js
            "props": {
                "titleProps": {
                    "component": "p",
                    "variant": "h6",
                    "color": "#3D3D3D",
                },
                "messageProps": {
                    "color": "#3D3D3D",
                },
                "iconProps": {
                    // "color": "textPrimary", // disabled this because Failed prop type: Invalid prop `color` of value `textPrimary` supplied to `ForwardRef(SvgIcon)`, expected one of ["action","disabled","error","inherit","primary","secondary"].
                    "fontSize": "large",
                },
                "showMessage": true,
            },
            "sx": (theme) => ({
                "display": "flex",
                "flexDirection": "column",
                "alignItems": "center",
                "padding": theme.spacing(4,2),
                "opacity": 0.35,
                "textAlign": "center",
                [theme.breakpoints.up('md')]: {
                    "padding": theme.spacing(8,2),
                },
                "& .MuiTypography-root": {
                    "fontSize": "1.375rem",
                    "lineHeight": "2rem",
                },
                "& .noActions-icon": {

                },
                ...(theme.props.showBoxContainer && {
                    "alignItems": "start",
                    "opacity": 1,
                    "marginBottom": theme.spacing(4),
                    [theme.breakpoints.up('md')]: {
                        "padding": 0,
                    },
                }),
            }),
        },
        "formTextField": {
        },
        "formSwitchField": { // options: src/components/MyAccount/forms/FormSwitchField/defaults.js
        },
        "loginSignup": { // options: src/components/MyAccount/modals/defaults.js
        },
        "backToWebsite": {   // options: src/components/MyAccount/modals/backToWebsite/defaults.js
        },
        "logoLogin": {  // options: src/components/MyAccount/Logo/defaults.js
        },
        "actionCardContainer": { // Wrapper around action cards component
        },
        "actionCard": { // options: src/components/MyAccount/actions/action/defaults.js - actionCardContainer/actionCard
            "props": {
                "cardAction": (theme) => ({
                    "color": theme.palette.text.primary,
                }),
            },
        },
        "actionCardViewing": { // override options from `actionCard` subtheme
        },
        "actionCardOffer": { // override options from `actionCardOffer` subtheme
        },
        "actionCardTenancy": { // override options from `actionCardTenancy` subtheme
        },
        "actionCardFeedback": { // override options from `actionCardFeedback` subtheme
        },
        "actionCardDocument": { // override options from `actionCardDocument` subtheme
        },
        "actionCardWorksorder": { // override options from `actionCardWorksorder` subtheme
        },
        "actionCardInspection": { // override options from `actionCardInspection` subtheme
        },
        "actionCardAppointment": { // override options from `actionCardAppointment` subtheme
        },
        "dialogDefault": { // options: src/components/MyAccount/modals/default/defaults.js
            // "sx": (theme) => ({
            //     // "display": "flex",
            //     // "alignItems": "flex-end",
            //     "& .dialogDefault-container": {
            //         // [theme.breakpoints.down('md')]: {
            //             // "position": "relative",
            //             // // "bottom": 0,
            //             // // "display": "flex",
            //             // // "alignItems": "flex-end",
            //             // "width": "100%",
            //             // "height": "100%",
            //         // },
            //     },
            //     "& .dialogDefault-paper": {
            //         [theme.breakpoints.up('md')]: {
            //             "borderRadius": "6px",
            //         },
            //         "& .MuiDialogTitle-root": { // This is the dialog title container
            //             // "& .MuiTypography-root": { // This is the title
            //                 // "maxWidth": "82%",
            //                 // "marginRight": "20px",
            //                 "textAlign": "center",
            //                 "fontSize": "28px",
            //                 "lineHeight": "32px",
            //                 "fontWeight": "400",
            //                 // "color": theme.palette.accent.main
            //             // },
            //         },
            //     },
            //     "& .dialogDefault-paperFullScreen": {
            //         // "position": "absolute",
            //         // "bottom": 0,
            //         // "left": 0,
            //         // "height": "auto",
            //         "borderRadius": "6px 6px 0 0",
            //     },
            //     "& .dialogDefault-close": {
            //         "position": "absolute",
            //         "top": "20px",
            //         "right": "20px",
            //         "width": "24px",
            //         "height": "24px",
            //         "zIndex": 9999,
            //     },
            //     "& .dialogDefault-content": {
            //         "padding": theme.spacing(3.75, 5),
            //         [theme.breakpoints.up('md')]: {
            //             "padding": theme.spacing(3.75, 10),
            //         }
            //     }
            // })
        },
        "dialogPreference": { // options: src/components/MyAccount/user/propertyPreference/dialogDefaults.js
        },
        "dialogLogout": {// options: src/components/MyAccount/user/logoutDialog/defaults.js
        },
        "dialogChildren": { // options: src/components/MyAccount/property/components/reportFaultBtn/dialogChildrenDefaults.js
        },
        "actionCardDialog": { // options: src/components/MyAccount/modals/action/defaults.js
            "subthemes": {
                "contactModule": { // Overriding how the contact module renders in action dialog
                    "props": {
                        "cardModule": {
                            "elevation": 0,
                        },
                        "cardModuleButtonTelProps": {
                            "variant": "contained",
                            "color": "secondary",
                        },
                        "cardModuleButtonEmailProps": {
                            "variant": "contained",
                            "color": "secondary",
                        },
                    },
                    "sx": (theme) => ({
                        "& .contactModule": {
                            "padding": 0,
                            "borderRadius": 0,
                        },
                        "& .cardContent": {
                            "display": "flex",
                            "flexDirection": "column",
                            "alignItems": "center",
                            "padding": 0,
                            "textAlign": "center",
                        },
                        "& .cardModuleTitle": {
                            "marginBottom": theme.spacing(4),
                        },
                        "& .cardActions": {
                            "flexDirection": "column",
                            "padding": 0,
                            "marginTop": theme.spacing(3),
                            "& > div": {
                                "width": "100%",
                            }
                        },
                        "& .cardModuleUserTitle": {
                            "color": theme.palette.text.primary,
                        },
                        "& .cardModuleUserJobTitle": {
                            "color": theme.palette.text.primary,
                        },
                        "& .cardModuleButton": {
                            "width": "100%",
                            "marginTop": theme.spacing(2),
                        },
                        "& .avatar": {
                            "marginBottom": theme.spacing(2),
                            // "backgroundColor": "#ff0000",
                            "width": 80,
                            "height": 80,
                        },
                        "& .closeCard": {
                            "color": theme.palette.grey.grey2,
                            "marginTop": theme.spacing(3),
                        },
                    }),
                },
            }
        },
        "actionDialogChildren": { // options: src/components/MyAccount/actions/action/defaults.js
            "subthemes": {
                "globalForm": { // Overriding how the globalForm renders in action dialog
                    "props": {
                        "formButton": {
                            "variant": "contained",
                            "color": "primary",
                            "fullWidth": true,
                        },
                    },
                    "sx": (theme) => ({
                        "& .globalForm-globalFormForm": {
                            "width": "100%",
                        },
                        "& .globalForm-fieldContainer": {
                            "display": "flex",
                            "flexDirection": "column",
                            "marginTop": theme.spacing(3),
                        },
                        "& .globalForm-textField": {
                            "width": "100%",
                            "marginBottom": theme.spacing(2.375),
                        },
                        "& .globalForm-selectField": {
                            "width": "100%",
                            "marginBottom": theme.spacing(3),
                        },
                        "& .react-datepicker": {
                            "width": "100%",
                            "marginBottom": theme.spacing(3),
                            "& .react-datepicker__time-container": {
                                "& li.react-datepicker__time-list-item": {
                                    "display": "flex",
                                    "alignItems": "center",
                                    "&.react-datepicker__time-list-item--disabled": {
                                        "display": "none",
                                    },
                                },
                                "& .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected": {
                                    "backgroundColor": theme.palette.primary.main,
                                },
                                "& .react-datepicker__day--selected": {
                                    "backgroundColor": theme.palette.primary.main,
                                    "color": "#fff",
                                    "&:hover": {
                                        "backgroundColor": theme.palette.primary.main,
                                    },
                                },
                            },
                        },
                        "& .suggestionsContainer": {
                            "position": "relative",
                            "& .suggestionsContainerOpen": {
                                "position": "absolute",
                                "zIndex": 998,
                                "top": "50px",
                                "left": 0,
                                "right": 0,
                            },
                            "& .suggestionsList": {
                                "margin": 0,
                                "padding": 0,
                                "listStyleType": "none",
                            },
                            "& .suggestion": {
                                "display": "block",
                                "padding": "10px 15px",
                                "&:hover": {
                                    "cursor": "pointer",
                                    "background": theme.palette.grey.grey3,
                                },
                            },
                        },
                        "& .globalForm-fieldsRadio": {
                            "marginBottom": theme.spacing(2),
                        },
                        "& .globalForm-actionContainer": {
                        },
                        "& .globalForm-fieldsTitle": {
                            "marginBottom": theme.spacing(3),
                        },
                        "& .globalForm-formButton": {

                            "marginTop": theme.spacing(2.75),
                        },
                    }),
                },
            },
        },
        "activityCard": { // options: src/components/MyAccount/activities/defaults.js
        },
        "profileCard": { // options: src/components/MyAccount/user/profileCard/defaults.js
            "props": {
                "cardProps": {
                    // "variant": "outlined",
                    "elevation": 0,
                },
            },
            "sx": (theme) => ({
                "marginBottom": theme.spacing(3.75),
                [theme.breakpoints.up('sm')]: {
                    "marginBottom": theme.spacing(4),
                },
                [theme.breakpoints.up('lg')]: {
                    "marginBottom": theme.spacing(6),
                },
                "& .profileCard-header": {
                    "padding": 0,
                    "& .profileCard-avatar": {
                        "marginRight": 0,
                        [theme.breakpoints.up('sm')]: {
                            "marginRight": 0,
                        },
                    },
                    "& .profileCard-avatar-img": {
                        "marginRight": theme.spacing(2.5),
                        [theme.breakpoints.up('sm')]: {
                            "marginRight": theme.spacing(3.75),
                        },
                    },
                    "& .profileCard-title": {
                        "fontWeight": 400,
                        "fontSize": theme.h4,
                        "lineHeight": theme.h3LineHm,
                        "textTransform": "capitalize",
                        [theme.breakpoints.up('sm')]: {
                            "fontSize": theme.h3,
                            "lineHeight": theme.h3LineHm,
                        },
                        [theme.breakpoints.down('sm')]: {
                            "fontSize": theme.h5,
                            "lineHeight": theme.h5LineHm,
                            "marginTop":"20px"
                        }

                    },
                    "& .profileCard-subheader": {
                        "color": theme.palette.text.primary,
                    },
                },
                "& .cardHeaderCaption": {
                    "color": theme.palette.grey.grey2,
                    [theme.breakpoints.up('lg')]: {
                        "display": "none",
                    }
                },
            }),
        },
        "userAvatar": { // options: src/components/MyAccount/user/avatar/defaults.js
        },
        "tabsMyAccount": { // options: src/components/MyAccount/tabs/defaults.js
            "sx": (theme) => ({
                "flexGrow": 1,
                "marginBottom": theme.moduleSpacing,
                [theme.breakpoints.up('sm')]: {
                    "marginBottom": theme.moduleSpacingSm,
                },
                [theme.breakpoints.up('lg')]: {
                    "marginBottom": theme.moduleSpacingLg,
                },
                // "backgroundColor": theme.palette.background.paper,
                "& .tabsMyAccount-tabs": {
                    "borderBottom": "1px solid #d8d8d8",
                    "marginLeft": `-${theme.gridItemPmobile}`,
                    "marginRight": `-${theme.gridItemPmobile}`,
                    [theme.breakpoints.up('sm')]: {
                        "marginLeft": `-${theme.gridItemPtablet}`,
                        "marginRight": `-${theme.gridItemPtablet}`,
                        "paddingLeft": theme.gridItemPtablet,
                        "paddingRight": theme.gridItemPtablet,
                    },
                    [theme.breakpoints.up('lg')]: {
                        "marginLeft": `calc(-${theme.gridItemPdesktop} * 2)`,
                        "marginRight": `calc(-${theme.gridItemPdesktop} * 2)`,
                        "paddingLeft": `calc(${theme.gridItemPdesktop} * 2)`,
                        "paddingRight": `calc(${theme.gridItemPdesktop} * 2)`,
                    },
                    "& .tabsMyAccount-indicator": {
                        "height": "3px",
                        "backgroundColor": theme.palette.secondary.main,
                    },
                    "& .tabsMyAccount-scrollButtons": {
                        "alignItems": "flex-start",
                        "& svg": {
                            "marginTop": "12px",
                        },
                        "&.Mui-disabled": {
                            "opacity": 0.3,
                        },
                    },
                },
                "& .tabsMyAccount-tab": {
                    "fontFamily": theme.secondaryFontFamily,
                    "textTransform": "none",
                    [theme.breakpoints.up('sm')]: {
                        "minWidth": "auto",
                        "paddingLeft": 0,
                        "paddingRight": 0,
                        "marginRight": theme.spacing(5),
                        "fontSize":"22px"
                    },
                    [theme.breakpoints.up('md')]: {
                        "paddingBottom": theme.spacing(3.5),
                    },
                    "&.tabsMyAccount-selected": {
                        "color": theme.palette.common.black,
                    },
                    "& .tabsMyAccount-wrapper": {
                        "fontSize": theme.copyright,
                        [theme.breakpoints.up('sm')]: {
                            "fontSize": theme.bodyLarge,
                        }
                    },
                },
                "& .tabsMyAccount-tabPanel": {
                    "padding": 0,
                },
                "& .tabsMyAccount-tabPanelBox": {
                    "padding": theme.spacing(2.75, 0, 0, 0),
                }
            }),
        },
        "calendar": { // options: src/components/MyAccount/calendar/defaults.js
        },
        "simpleModule": { // options: src/components/MyAccount/simpleModule/defaults.js
            "sx": (theme) => ({
                "& .simpleModule-cardModule": {
                    "borderRadius": theme.moduleBorderRadius,
                },
                "& .simpleModule-cardModule-media": {
                    "height": 140,
                },
                "& .simpleModule-cardContent": {
                    "padding": theme.modulePadding,
                },
                "& .simpleModule-cardActions": {
                    "padding": theme.modulePadding,
                    "paddingTop": "0!important",
                },
                "& .simpleModule-cardModuleTitle": {
                    "fontFamily": theme.tertiaryFontFamily,
                    "fontSize": "2rem",
                    "marginBottom": theme.spacing(1),
                },
                "& .simpleModule-cardModuleContent": {
                    "color": "#6A6A6A",
                },
                "& .simpleModule-cardModuleButton": {
                    "width": "100%",
                },
            }),
        },
        "contactModule": { // options: src/components/MyAccount/contactModule/defaults.js
            "props": {
                "cardModuleButtonTelProps": {
                    "variant": "contained",
                    "color": "secondary",
                },
                "cardModuleButtonEmailProps": {
                    "variant": "contained",
                    "color": "secondary",
                },
            },
        },
        "simpleContactModule": { // options: src/components/MyAccount/contactModule/simpleContact.js
        },
        "menuListIcons": { // options: src/components/MyAccount/iconMenuList/menuListIcons.js
        },
        "menuListIconsTabs": { // options: src/components/MyAccount/iconMenuList/menuListIconsTabs.js
        },
        "propertyResultComponent": {
            "subthemes": {
                "results": { // default property result styling
                    "sx": (theme) => ({
                        [theme.breakpoints.up('sm')]: {
                            "width": "auto",
                            "margin": theme.spacing(0, -0.75),
                        },
                        [theme.breakpoints.up('lg')]: {
                            "margin": theme.spacing(0, -2.375),
                        },
                    }),
                    "subthemes": {
                        "propertyresult": {
                            "props": {
                                "container": {
                                    "order": ['media', 'header', 'aside'],
                                    "sx": (theme) => ({
                                        "flexDirection": "row",
                                        "width": "100%",
                                        "paddingTop": theme.spacing(1.125),
                                        "paddingBottom": theme.spacing(1.125),
                                        [theme.breakpoints.up('sm')]: {
                                            "flexDirection": "column",
                                            "width": `calc(50% - ${theme.spacing(1.5)})`,
                                            "paddingTop": 0,
                                            "paddingBottom": 0,
                                            "marginBottom": theme.spacing(5),
                                            "marginLeft": theme.spacing(0.75),
                                            "marginRight": theme.spacing(0.75),
                                        },
                                        [theme.breakpoints.up('lg')]: {
                                            "width": `calc(33.33% - ${theme.spacing(4.75)})`,
                                            "marginLeft": theme.spacing(2.375),
                                            "marginRight": theme.spacing(2.375),
                                        },
                                    }),
                                },
                                "header": {
                                    "sx": (theme) => ({
                                        "width": "calc(100% - 110px)",
                                        [theme.breakpoints.up('sm')]: {
                                            "width": "100%",
                                            "marginTop": theme.spacing(3.25),
                                        },
                                        "& .propertyresult-address": {
                                            "fontWeight": "bold",
                                            [theme.breakpoints.up('sm')]: {
                                                "fontSize": theme.bodyLarge,
                                                "lineHeight": theme.lineHeightBLarge,
                                                "marginBottom": theme.spacing(0.25),
                                            },
                                        },
                                    }),
                                },
                                "aside": {
                                    "sx": (theme) => ({
                                        "width": "100%",
                                        "display": "flex",
                                        "flexDirection": "row",
                                        "alignItems": "center",
                                        "justifyContent": "flex-end",
                                        "& .propertyresult-price": {
                                            "marginRight": "auto",
                                            [theme.breakpoints.up('sm')]: {
                                                "fontSize": theme.bodyMedium,
                                                "lineHeight": theme.lineHeightBMedium,
                                            },
                                        },
                                    }),
                                },
                                "price": {
                                    "in": "aside",
                                    "variant": "bodySmall",
                                    "component": "p",
                                },
                                "address": {
                                    "in": "header",
                                    "variant": "bodyMedium",
                                    "component": "header",
                                },
                                "offer": {
                                    "in": "header",
                                    "variant": "bodyMedium",
                                },
                                "rooms": {
                                    "in": "header",
                                },
                                // "save": {
                                //     "in": "aside",
                                // },
                                // "removeProperty": {
                                //     "in": "aside",
                                // },
                                "media": {
                                    "sx": (theme) => ({
                                        "overflow": "hidden",
                                        "marginRight": theme.spacing(2.375),
                                        "& .propertyresult-link-image": {
                                            "display": "block",
                                        },
                                        "& .propertyresult-image": {
                                            "display": "block",
                                            "width": "90px",
                                            [theme.breakpoints.up('sm')]: {
                                                "width": "100%",
                                            }
                                        },
                                        [theme.breakpoints.up('sm')]: {
                                            "marginRight": 0,
                                        }
                                    })
                                },
                            },

                            // "typography": {
                            //     "h6": { "textTransform": "none" },
                            //     "subtitle1": { "variant": "bodyMedium", "textTransform": "none" },
                            //     "subtitle2": { "textTransform": "none" },
                            // },

                            "addressFormat": {
                                "separator": " , ",
                                "values": (p) => [p.display_address, p.street_name, p.district, p.area].filter(x => x).shift()
                                // "values": (p) => console.log(p)
                            },
                            "roomsFormat": {
                                "separator": " ",
                                "values": (p) => [p.bedrooms, p.receptions, p.bathrooms]
                            },
                        },
                    },
                },
            },
        },
        "savedPropertyResultComponent": {
            "subthemes": {
                "results": { // default property result styling
                    "sx": (theme) => ({
                        [theme.breakpoints.up('sm')]: {
                            "width": "auto",
                            "margin": theme.spacing(0, -0.75),
                        },
                        [theme.breakpoints.up('lg')]: {
                            "margin": theme.spacing(0, -2.375),
                        },
                    }),
                    "subthemes": {
                        "propertyresult": {
                            "props": {
                                "container": {
                                    "order": ['media', 'header', 'aside'],
                                    "sx": (theme) => ({
                                        "flexDirection": "column",
                                        "width": "100%",
                                        "fontFamily":theme.secondaryFontFamily,
                                        "marginBottom": theme.spacing(3),
                                        "marginTop": theme.spacing(2.5),
                                        [theme.breakpoints.up('sm')]: {
                                            "width": `calc(50% - ${theme.spacing(1.5)})`,
                                            "marginBottom": theme.spacing(5),
                                            "marginLeft": theme.spacing(0.75),
                                            "marginRight": theme.spacing(0.75),
                                        },
                                        [theme.breakpoints.up('lg')]: {
                                            "width": `calc(33.33% - ${theme.spacing(4.75)})`,
                                            "marginLeft": theme.spacing(2.375),
                                            "marginRight": theme.spacing(2.375),
                                        },
                                    }),
                                },
                                "header": {
                                    "sx": (theme) => ({
                                        "width": "100%",
                                        "marginTop": theme.spacing(1.5),
                                        [theme.breakpoints.up('sm')]: {
                                            "marginTop": theme.spacing(3.25),
                                        },
                                        "& .propertyresult-address": {
                                            // "fontWeight": "bold",
                                            [theme.breakpoints.up('sm')]: {
                                                "fontSize": theme.bodyLarge,
                                                "lineHeight": theme.lineHeightBLarge,
                                                "marginBottom": theme.spacing(0.25),
                                            },
                                            "& .propertyresult-link-image": {
                                                "color": theme.palette.primary.title,
                                                "fontSize": "18px",
                                                "lineHeight": "24px",
                                                "fontWeight": 600,
                                                "&:hover": {
                                                    "color": theme.palette.secondary.main,
                                                    "textDecoration": "none",
                                                }
                                            },
                                        },
                                    }),
                                },
                                "aside": {
                                    "sx": (theme) => ({
                                        "width": "100%",
                                        "display": "flex",
                                        "flexDirection": "row",
                                        "alignItems": "center",
                                        "justifyContent": "space-between",
                                        "marginTop": "auto",
                                        "flexWrap": "wrap",
                                        "& .propertyresult-price": {
                                            "marginTop": theme.spacing(0.75),
                                            "order": 1,
                                            "color": theme.palette.grey.grey1,
                                            [theme.breakpoints.up('sm')]: {
                                                "marginTop": theme.spacing(0),
                                                "fontSize": theme.bodyMedium,
                                                "lineHeight": theme.lineHeightBMedium,
                                            },
                                        },
                                        "& .propertyresult-rooms": {
                                            "order": 0,
                                            "flexBasis": "100%",
                                            "marginTop": theme.spacing(0.75),
                                            "& .roomsItem": {
                                                // "border":"1px solid black",
                                                // "padding":"5px 0px",
                                                "& svg": {
                                                    "color": theme.palette.secondary.main,
                                                    // "margin":"2px 3px 2px 0px",
                                                },
                                                "& .roomsItem-text":{
                                                    "marginTop":"1px"
                                                }

                                            }
                                        },
                                        "& .propertyresult-removeProperty": {
                                            "order": 2
                                        }
                                    }),
                                },
                                "price": {
                                    "in": "aside",
                                    "variant": "bodySmall",
                                    "component": "p",
                                },
                                "address": {
                                    "in": "header",
                                    "variant": "bodyMedium",
                                    "component": "header",
                                },
                                "offer": {
                                    "in": "header",
                                    "variant": "bodyMedium",
                                },
                                "rooms": {
                                    "in": "aside",
                                    "component": "div",
                                },
                                "summary": {
                                    "in": "media",
                                    "variant": "bodyMedium",
                                },
                                // "save": {
                                //     "in": "aside",
                                // },
                                "removeProperty": {
                                    "in": "aside",
                                },
                                "media": {
                                    "sx": {
                                        "position": "relative",
                                        "overflow": "hidden",
                                        "&.propertyresult-media": {
                                            "width": "100%",
                                        },
                                        "& .propertyresult-link-image": {
                                            "display": "block",
                                            "overflow": "hidden",
                                            "&:after": {
                                                "content": "''",
                                                "position": "absolute",
                                                "top": 0,
                                                "left": 0,
                                                "width": "100%",
                                                "height": "100%",
                                                "background": "rgba(0,0,0,0.2)",
                                                "zIndex": "2",
                                                "pointerEvents": "none",
                                            },
                                        },
                                        "& .propertyresult-image": {
                                            "display": "block",
                                            "width": "100%",
                                        },
                                        "& .propertyresult-removeProperty": {
                                            "position": "absolute",
                                            "top": "10px",
                                            "right": "10px",
                                            "zIndex": "20",
                                            "& svg": {
                                                "color": "#fff",
                                            },
                                        },
                                        "& .propertyresult-360tour": {
                                            "position": "absolute",
                                            "bottom": "15px",
                                            "right": "15px",
                                            "width": "auto",
                                            "zIndex": 20,
                                        },
                                        "& .propertyresult-summary": {
                                            "backgroundColor":"#1B1434",
                                            "textAlign": "center",
                                            "color": "#FFFFFF",
                                            "fontWeight": "500",
                                            "padding": "8px 20px",
                                            "position": "inherit",
                                            "zIndex": "3"
                                        },
                                    },
                                },
                            },

                            // "typography": {
                            //     "h6": { "textTransform": "none" },
                            //     "subtitle1": { "variant": "bodyMedium", "textTransform": "none" },
                            //     "subtitle2": { "textTransform": "none" },
                            // },

                            "addressFormat": {
                                "separator": " , ",
                                "values": (p) => [p.display_address, p.street_name, p.district, p.area].filter(x => x).shift()
                                // "values": (p) => console.log(p)
                            },
                            "roomsFormat": {
                                "separator": " ",
                                "values": (p) => [p.bedrooms, p.receptions, p.bathrooms]
                            },
                            "linkFormat": {
                                "separator": "-",
                                "target": "_blank",
                                "values": (p) => propertyLink(p)
                                // "values": (p) => console.log(p)
                            },
                            // "lettingsPricePrefix": "",
                            "lettingsPriceSuffix": " PCM",
                            // "salesPricePrefix": "",
                            // "salesPriceSuffix": "",
                        },
                    },
                },
            },
        },
        "savedSearchResults": {
            "props": {
                "resultLinkFun": (p) => resultLinkFun(p),
                "noactionTitle": "",
                "noactionMessage": "Looks like you don’t have any searches saved yet",
            },

            "sx": (theme) => ({
                "& .content": {
                    "display": "flex",
                    "flexFlow": "column",
                },
                "& .box-container": {
                    "display": "flex",
                    "flexWrap": "wrap",
                    "alignItems": "center",
                    "border": "1px solid #DDDDDD",
                    "borderRadius": "4px",
                    "padding": theme.spacing(5),
                    "order": "1",
                    "& .box-image": {
                        "background": theme.palette.grey.grey3,
                        "& svg": {
                            "fontSize": "2rem",
                            "color": theme.palette.primary.icon
                        }
                    },
                },
                "& .box-image-container": {
                    "display": "none",
                    [theme.breakpoints.up('lg')]: {
                        "display": "block"
                    },
                },
                "& .box-image-container, .box-btn-container, .box-content": {
                    [theme.breakpoints.down('lg')]: {
                        "flexBasis": "100%",
                        "maxWidth": "100%",
                    },
                },
                "& .box-content": {
                    [theme.breakpoints.down('lg')]: {
                        "padding": "20px 0",
                        "flexBasis": "100%",
                        "maxWidth": "100%",
                    },
                    [theme.breakpoints.up('lg')]: {
                        "paddingLeft": "10px",
                    },
                },
                "& .box-btn-container": {
                    "textAlign": "left",
                    "paddingTop": theme.spacing(1),
                    [theme.breakpoints.up('lg')]: {
                        "textAlign": "right",
                        "paddingTop": theme.spacing(0),
                    }
                },
                "& .box-container-title": {
                    "fontFamily": theme.primaryFontFamily,
                    "fontWeight": theme.typography.fontWeightBold,
                    "color": theme.palette.grey.grey6,
                    "marginBottom": "5px",
                    [theme.breakpoints.up('xl')]: {
                        "marginBottom": "10px",
                    },
                },
                "& .box-container-text": {
                    "fontFamily": theme.secondaryFontFamily,
                    "fontSize": theme.bodyMedium,
                    "lineHeight": theme.lineHeightBMedium,
                    "fontWeight": "normal",
                    [theme.breakpoints.down('md')]: {
                        "marginBottom": "5px",
                        "display": "block"
                    },
                    "color": theme.palette.grey.grey2,
                },
                "& .savedSearchResults-card": {
                    "marginBottom": 0,
                    "paddingTop": theme.spacing(1),
                    "paddingBottom": theme.spacing(1),
                    "&:last-child": {
                        "marginBottom": theme.spacing(4),
                    },
                    "&.disabled": {
                        "& .savedSearchResults-cardHeader": {
                            "& > div": {
                                "opacity": 0.5
                            },
                            "& .savedSearchResults-action": {
                                "opacity": 1
                            }
                        },
                    }
                },
                "& .savedSearchResults-cardActionButton": {
                    "display": "none",
                },
                "& .savedSearchResults-cardHeader": {
                    "padding": theme.spacing(1, 0),
                    [theme.breakpoints.up('sm')]: {
                        "padding": theme.spacing(1.5, 0),
                    },
                    "& .savedSearchResults-avatar": {
                        "marginRight": 0,
                    },
                    "& .savedSearchResults-title": {
                        "color": theme.palette.primary.dark,
                        "fontSize": theme.bodySmall,
                        "lineHeight": theme.lineHeightBMedium,
                        "marginBottom": theme.spacing(0.25),
                        "fontFamily": theme.secondaryFontFamily,
                        "fontWeight": "400",
                        [theme.breakpoints.up('sm')]: {
                            "fontSize": theme.bodyMedium,
                            "lineHeight": theme.lineHeightBLarge,
                            "marginBottom": theme.spacing(0.5),
                        },
                        [theme.breakpoints.up('md')]: {
                            "fontSize": "1.375rem",
                            "lineHeight": "2rem",
                        },
                    },
                    "& .savedSearchResults-subheader": {
                        "color": "#6A6A6A",
                        [theme.breakpoints.up('sm')]: {
                            "fontSize": theme.bodyMedium,
                            "lineHeight": theme.lineHeightBMedium,
                        }
                    },
                    "& .savedSearchResults-action": {
                        "alignSelf": "center",
                        "marginRight": theme.spacing(0.5),
                    }
                },
                "& .savedSearchResults-cardContent": {
                    "display": "none",
                    [theme.breakpoints.up("lg")]: {
                        "display": "flex",
                    },
                    "& .cardContentItemName": {
                        "color": "#292929",
                        "fontWeight": "bold",
                    },
                    "& .cardContentItemParams": {
                        "color": theme.palette.text.secondary,
                        "textTransform": "capitalize",
                    }
                },
            }),
        },
        "savedProperties": {
            "props": {
                "noActionMessage": "Get started in just a few seconds",
                "containerText": "Looking to buy or rent property in Essex and Kent? Save your property preference and we will email you a list of properties that match your search criteria so that you do not miss out on the action.",
                "resultLinkFun": (p) => resultLinkFun(p),
            },
            "sx": (theme) => ({
                "& .box-container": {
                    "display": "flex",
                    "flexWrap": "wrap",
                    "alignItems": "center",
                    "border": "1px solid #DDDDDD",
                    "borderRadius": "4px",
                    "padding": theme.spacing(5),
                    "& .box-image": {
                        "background": theme.palette.grey.grey3,
                        "& svg": {
                            "fontSize": "2rem",
                            "color": theme.palette.primary.icon
                        }
                    },
                },
                "& .box-image-container": {
                    "display": "none",
                    [theme.breakpoints.up('lg')]: {
                        "display": "block"
                    },
                },
                "& .box-image-container, .box-btn-container, .box-content": {
                    [theme.breakpoints.down('lg')]: {
                        "flexBasis": "100%",
                        "maxWidth": "100%",
                    },
                },
                "& .box-content": {
                    [theme.breakpoints.down('lg')]: {
                        "padding-bottom": "10px",
                        "flexBasis": "100%",
                        "maxWidth": "100%",
                    },
                    [theme.breakpoints.up('lg')]: {
                        "paddingLeft": "10px",
                    },
                },
                "& .box-btn-container": {
                    "textAlign": "left",
                    "paddingTop": theme.spacing(1),
                    [theme.breakpoints.up('lg')]: {
                        "textAlign": "right",
                        "paddingTop": theme.spacing(0),
                    }
                },
                "& .box-container-title": {
                    "fontFamily": theme.primaryFontFamily,
                    "fontWeight": theme.typography.fontWeightBold,
                    "fontSize": theme.bodyMediumSize,
                    "lineHeight": theme.h5LineH,
                    "marginBottom": "5px",
                    [theme.breakpoints.up('xl')]: {
                        "marginBottom": "10px",
                    },
                },
                "& .box-container-text": {
                    "fontFamily": theme.secondaryFontFamily,
                    "fontSize": theme.bodyMedium,
                    "lineHeight": theme.lineHeightBMedium,
                    "fontWeight": "normal",

                    [theme.breakpoints.down('md')]: {
                        "marginBottom": "5px",
                        "display": "block"
                    },
                    "color": theme.palette.grey.grey2
                }
            }),
        },
        "latestProperties": {
            "subthemes": {
                "results": {
                    "subthemes": {
                        "propertyresult": {
                            "props": {
                                "container": {
                                    "direction": "row",
                                    "span": "full",
                                    "order": ['media', 'header'],
                                    "sx": (theme) => ({
                                        "paddingTop": theme.spacing(1.125),
                                        "paddingBottom": theme.spacing(1.125),
                                        "marginBottom": theme.moduleSpacing,
                                        [theme.breakpoints.up('sm')]: {
                                            "paddingTop": theme.spacing(3.375),
                                            "paddingBottom": theme.spacing(3.375),
                                            "marginBottom": theme.moduleSpacingSm,
                                            "borderBottom": `1px solid ${theme.palette.grey.grey4}`,
                                        },
                                        [theme.breakpoints.up('lg')]: {
                                            "marginBottom": theme.moduleSpacingLg,
                                        },
                                        "&:last-child": {
                                            "borderBottom": "none",
                                        },
                                    }),
                                },
                                "header": {
                                    "sx": {
                                        "width": "calc(100% - 110px)",
                                        "& .propertyresult-address": {
                                            "fontWeight": "bold",
                                        },
                                    },
                                },
                                "price": {
                                    "in": "header",
                                    "variant": "bodySmall",
                                },
                                "address": {
                                    "in": "header",
                                    "variant": "bodyMedium",
                                    "component": "header",
                                    "sx": {
                                        "root": {
                                            "fontWeight": "bold"
                                        }
                                    }
                                },
                                "offer": {
                                    "in": "header",
                                    "variant": "bodyMedium",
                                },
                                "media": {
                                    "overflow": "hidden",
                                    "width": 90,
                                    "sx": (theme) => ({
                                        "marginRight": theme.spacing(2.375),
                                        "& .propertyresult-link-image": {
                                            "display": "block",
                                        },
                                        "& .propertyresult-image": {
                                            "display": "block",
                                        },
                                    })
                                },
                            },

                            // "typography": {
                            //     "h6": { "textTransform": "none" },
                            //     "subtitle1": { "variant": "bodyMedium", "textTransform": "none" },
                            //     "subtitle2": { "textTransform": "none" },
                            // },

                            "address": {
                                "separator": " • ",
                                // "values": (p) => [p.district,p.street_name].filter(x=>x).shift()
                                "values": (p) => [p.display_address, p.street_name, p.district, p.area].filter(x => x).shift().toUpperCase() /*should really be CSS */
                            },
                        },
                    },
                },
            },
        },
        "singleProperty": {
            "sx": (theme) => ({
                "root": {
                    "padding": theme.spacing(4, 5),
                    "borderRadius": theme.moduleBorderRadius,
                    [theme.breakpoints.up('lg')]: {
                        "marginTop": theme.spacing(0),
                    }
                },
            }),
            "subthemes": {
                "results": {
                    "subthemes": {
                        "propertyresult": {
                            "props": {
                                "container": {
                                    "direction": "column",
                                    "order": ['media', 'header'],
                                    // "sx": (theme) => ({
                                    //     "paddingTop": theme.spacing(1.125),
                                    //     "paddingBottom": theme.spacing(1.125),
                                    //     [theme.breakpoints.up('sm')]: {
                                    //         "paddingTop": theme.spacing(3.375),
                                    //         "paddingBottom": theme.spacing(3.375),
                                    //         "borderBottom": `1px solid ${theme.palette.grey.grey4}`,
                                    //     },
                                    //     "&:last-child": {
                                    //         "borderBottom": "none",
                                    //     },
                                    // }),
                                },
                                "header": {
                                    "sx": (theme) => ({
                                        "width": "100%",
                                        "& .propertyresult-address": {
                                            "marginTop": theme.spacing(3.25),
                                            "marginBottom": theme.spacing(0.25),
                                            "fontWeight": "bold",
                                        },
                                    }),
                                },
                                // "summary": {
                                //     "in": "media",
                                //     "variant": "bodyMedium",
                                // },
                                "offer": {
                                    "in": "header",
                                    "variant": "bodyMedium"
                                },
                                "address": {
                                    "in": "header",
                                    "variant": "bodyLarge",
                                    "component": "header",
                                },
                                "media": {
                                    "sx": (theme) => ({
                                        "overflow": "hidden",
                                        "position": "relative",
                                        "& .propertyresult-link-image": {
                                            "display": "block",
                                        },
                                        "& .propertyresult-image": {
                                            "display": "block",
                                            "width": "100%",
                                            "height": "auto",
                                        },
                                        "& .propertyresult-summary": {
                                            "textTransform": "capitalize",
                                            "position": "absolute",
                                            "top": 0,
                                            "left": 0,
                                            "width": "100%",
                                            "backgroundColor": "#4F4F4F",
                                            "zIndex": 2,
                                            "textAlign": "center",
                                            "color": "#fff",
                                            "fontWeight": "bold",
                                            "padding": theme.spacing(1),
                                        }
                                    })
                                },
                            },

                            // "typography": {
                            //     "h6": { "textTransform": "none" },
                            //     "subtitle1": { "variant": "bodyMedium", "textTransform": "none" },
                            //     "subtitle2": { "textTransform": "none" },
                            // },

                            // "address": {
                            //     "separator": " • ",
                            //     "values": (p) => [p.display_address,p.street_name,p.district,p.area].filter(x=>x).shift().toUpperCase() /*should really be CSS */
                            // },
                        },
                    },
                },
            },
        },
        "myPropertyPageSingleHeaderProperty": {
            "sx": (theme) => ({
                "width": `calc(100% + (${theme.gridItemPmobile} * 2))`,
                "marginLeft": `-${theme.gridItemPmobile}`,
                "marginRight": `-${theme.gridItemPmobile}`,
                "marginBottom": theme.spacing(4),
                [theme.breakpoints.up('sm')]: {
                    "width": `calc(100% + (${theme.gridItemPtablet} * 2))`,
                    "marginLeft": `-${theme.gridItemPtablet}`,
                    "marginRight": `-${theme.gridItemPtablet}`,
                    "marginBottom": 0,
                },
            }),
            "subthemes": {
                "globalComponentLayout": {
                    "sx": {
                        // Remove global space between components and modules
                        "marginBottom": 0,
                    },
                },
                "singleProperty": {
                    "sx": (theme) => ({
                        "root": {
                            "padding": 0,
                            "margin": 0,
                        },
                    }),
                    "subthemes": {
                        "results": {
                            "subthemes": {
                                "propertyresult": {
                                    "props": {
                                        "header": {
                                            "sx": (theme) => ({
                                                "width": "100%",
                                                "& .propertyresult-address": {
                                                    "display": "none",
                                                    "fontWeight": "normal",
                                                    "margin": 0,
                                                    "padding": `0 ${theme.gridItemPtablet}`,
                                                    [theme.breakpoints.up('sm')]: {
                                                        "display": "flex",
                                                        "padding": `0 ${theme.gridItemPtablet}`,
                                                        "marginBottom": theme.spacing(4),
                                                        "marginTop": theme.spacing(3.75),
                                                    },
                                                },
                                                "& .propertyresult-offerText": {
                                                    "display": "none",
                                                    [theme.breakpoints.up('md')]: {
                                                        "display": "flex"
                                                    },
                                                },
                                            }),
                                        },
                                        "address": {
                                            "in": "header",
                                            "variant": "h3",
                                            "component": "h2",
                                        },
                                        "media": {
                                            "sx": (theme) => ({
                                                "position": "relative",
                                                "overflow": "hidden",
                                                "& .propertyresult-link-image": {
                                                    "display": "block",
                                                },
                                                "& .propertyresult-image": {
                                                    "overflow": "hidden",
                                                    "display": "block",
                                                    "width": "100%",
                                                    "height": "auto",
                                                    "marginBottom": 0,
                                                },
                                                "& .propertyresult-summary": {
                                                    "textTransform": "capitalize",
                                                    "position": "absolute",
                                                    "top": 0,
                                                    "left": 0,
                                                    "width": "100%",
                                                    "backgroundColor": "#4F4F4F",
                                                    "zIndex": 2,
                                                    "textAlign": "center",
                                                    "color": "#fff",
                                                    "fontWeight": "bold",
                                                    "padding": theme.spacing(1),
                                                }
                                            })
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
        "profileMyAccountForm": { // options: src/components/MyAccount/user/profile/defaults.js
        },
        "profileMyAccountChangePassword": { // options: src/components/MyAccount/user/password/defaults.js
        },
        "profileMyAccountNotificationPreferences": {
        },
        "pendingItems": { // options: src/components/MyAccount/progress/defaults.js
            "sx": (theme) => ({
                "& .progressContainer": {
                    "width": "100%",
                },
                "& .progressComponent-root": {
                    "height": 5,
                    "borderRadius": 2,
                },
                "& .progressComponent-colorPrimary": {
                    "backgroundColor": "#dddddd",
                },
                "& .progressComponent-bar": {
                    "borderRadius": 2,
                    "backgroundColor": theme.palette.primary.main,
                },
                "& .progressValueTypography": {
                    "fontFamily": theme.tertiaryFontFamily,
                    "fontSize": "3.125rem",
                    "color": theme.palette.primary.dark,
                    "marginBottom": theme.spacing(1),
                    [theme.breakpoints.up('sm')]: {
                        "fontSize": "3.5rem",
                        "marginBottom": theme.spacing(1.8),
                    },
                },
                "& .pendingHeading": {
                    "color": theme.palette.grey.grey2,
                    "fontWeight": "bold",
                    "marginBottom": theme.spacing(1.25),
                    [theme.breakpoints.up('sm')]: {
                        "fontSize": theme.bodyMedium,
                        "lineHeight": theme.lineHeightBLarge,
                        "marginBottom": theme.spacing(2),
                    },
                },
                "& .pendingTextTitle": {
                    "color": theme.palette.primary.dark,
                    "fontWeight": "bold",
                    "marginRight": 5,
                    [theme.breakpoints.up('sm')]: {
                        "fontSize": theme.bodyLarge,
                        "lineHeight": theme.lineHeightBLarge,
                    },
                },
                "& .pendingTextStatus": {
                    "color": theme.palette.primary.dark,
                    [theme.breakpoints.up('sm')]: {
                        "fontSize": theme.bodyLarge,
                        "lineHeight": theme.lineHeightBLarge,
                    },
                },
                "& .pendingIconFinished": {
                    "color": "#8ABB5A",
                    "marginRight": theme.spacing(2),
                },
                "& .pendingIconPending": {
                    "marginRight": theme.spacing(2),
                },
                "& .progressValueContainer": (theme) => ({
                    "display": "flex",
                    "flexDirection": "column",
                    "marginBottom": theme.spacing(3),
                    [theme.breakpoints.up('sm')]: {
                        "marginBottom": theme.spacing(5),
                    },
                }),
                "& .progressValueContainerProgress": {
                    "order": 0,
                    "width": "100%",
                    "mr": 1,
                },
                "& .progressValueContainerNumber": {
                    "order": 1,
                },
                "& .pendingContainer": (theme) => ({
                    "display": "flex",
                    "flexDirection": "row",
                    "marginBottom": theme.spacing(1),
                    "&:last-child": {
                        "marginBottom": 0,
                    }
                }),
                "& .pendingCircleStyle": {
                    "display": "flex",
                    "flexDirection": "row",
                    "alignItems": "center",
                    // "paddingBottom": theme.spacing(1),
                },
            }),
        },
        "feedbackProgress": { // options: src/components/MyAccount/feedbackProgress/defaults.js
            "subthemes": {
                "globalComponentLayout": {
                    "props": {
                        "containerProps": {
                            "elevation": 2,
                        },
                        "contentProps": {

                        },
                        "titleProps": {
                            "variant": "caption",
                            "component": "h2",
                        },
                        "titleLinkMoreProps": {
                            "variant": "caption",
                        },
                        "titleLinkMoreTooltipProps": {
                            "title": "Tooltip",
                            "placement": "top-end",
                            "arrow": true,
                        },
                    },
                    "sx": (theme) => ({
                        "padding": 0,
                        "borderRadius": theme.moduleBorderRadius,
                        [theme.breakpoints.down('sm')]: {
                            "boxShadow": "none",
                        },
                        [theme.breakpoints.up('sm')]: {
                            "padding": theme.modulePadding,
                        },
                        "& .globalComponentLayout-titleContainer": {
                            "display": "flex",
                            "flexDirection": "row",
                            "alignItems": "flex-end",
                            "minHeight": 56,
                            "paddingBottom": theme.spacing(2),
                            "color": "red",
                            [theme.breakpoints.up('sm')]: {
                                "paddingBottom": theme.spacing(3),
                            },

                        },
                        "& .globalComponentLayout-title": {
                            "fontWeight": "bold",
                            "color": theme.palette.grey.grey6,
                            [theme.breakpoints.up('sm')]: {
                                "fontSize": theme.h5,
                                "lineHeight": theme.h5LineH,
                                "color": theme.palette.text.primary,
                            },
                        },
                        "& .globalComponentLayout-titleLinkMore": {
                            "fontWeight": "bold",
                            "color": theme.palette.grey.grey2,
                            "marginLeft": "auto",
                            [theme.breakpoints.up('sm')]: {
                                "fontSize": theme.bodyMedium,
                                "lineHeight": theme.lineHeightBMedium,
                                "fontWeight": "normal",
                                "color": theme.palette.text.primary,
                            },
                        },
                    }),
                }
            }
        },
        "swipeEdge": { // options: src/components/MyAccount/customSidebarDrawer/swipeEdgeDefaults.js
            "subthemes": {
                "menuListIconsTabs": {
                    "sx": (theme) => ({
                        "& .menuListIconsTabs": {
                            "padding": 0,
                            "margin": 0,
                        },
                        "& .menuListIconsTabs-vertical": {
                        },
                        "& .menuListIconsTabs-indicator": {
                            "display": "none",
                        },
                        "& .menuListIconsTabs-tab": {
                            "fontWeight": "bold",
                            "textTransform": "none",
                            "minHeight": "58px",
                            "borderBottom": `1px solid ${theme.palette.divider}`,
                            "paddingTop": theme.spacing(1.5),
                            "paddingBottom": theme.spacing(1.5),
                            "paddingLeft": 0,
                            "paddingRight": theme.gridItemPmobile,
                            "opacity": 1,
                            [theme.breakpoints.up('sm')]: {
                                "fontSize": theme.bodyMedium,
                                "lineHeight": theme.lineHeightBMedium,
                                // "paddingLeft": theme.gridItemPtablet,
                                "paddingRight": theme.gridItemPtablet,
                            },
                            [theme.breakpoints.up('md')]: {
                                "padding": theme.spacing(1.5, 0),
                            },
                            "&.menuListIconsTabs-tab-wrapper": {
                                "display": "flex",
                                "flexDirection": "row",
                                "alignItems": "center",
                                "justifyContent": "flex-start",
                            },
                        },
                        "& .menuListIconsTabs-tab-labelIcon svg": {
                            "color": theme.palette.text.primary,
                            "marginBottom": 0,
                            "marginRight": theme.spacing(2.25),
                        }
                    })
                },
            },
        },
        "reportFaultBtn": { // options: src/components/MyAccount/property/components/reportFaultBtn/defaults.js
            "subthemes": {
                "dialogChildren": {
                    "subthemes": {
                        "globalForm": { // Overriding how the globalForm renders in action dialog
                            "props": {
                                "formButton": {
                                    "variant": "contained",
                                    "color": "primary",
                                    "fullWidth": true,
                                },
                            },
                            "sx": (theme) => ({
                                "& .globalForm-globalFormForm": {
                                    "width": "100%",
                                },
                                "& .globalForm-fieldContainer": {
                                    "display": "flex",
                                    "flexDirection": "column",
                                },
                                "& .globalForm-textField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(2.375),
                                },
                                "& .globalForm-selectField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .react-datepicker": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(3),
                                    "& .react-datepicker__time-container": {
                                        "& li.react-datepicker__time-list-item": {
                                            "display": "flex",
                                            "alignItems": "center",
                                            "&.react-datepicker__time-list-item--disabled": {
                                                "display": "none",
                                            },
                                        },
                                        "& .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected": {
                                            "backgroundColor": theme.palette.primary.main,
                                        },
                                        "& .react-datepicker__day--selected": {
                                            "backgroundColor": theme.palette.primary.main,
                                            "color": "#fff",
                                            "&:hover": {
                                                "backgroundColor": theme.palette.primary.main,
                                            },
                                        },
                                    },
                                },
                                "& .suggestionsContainer": {
                                    "position": "relative",
                                    "& .suggestionsContainerOpen": {
                                        "position": "absolute",
                                        "zIndex": 998,
                                        "top": "50px",
                                        "left": 0,
                                        "right": 0,
                                    },
                                    "& .suggestionsList": {
                                        "margin": 0,
                                        "padding": 0,
                                        "listStyleType": "none",
                                    },
                                    "& .suggestion": {
                                        "display": "block",
                                        "padding": "10px 15px",
                                        "&:hover": {
                                            "cursor": "pointer",
                                            "background": theme.palette.grey.grey3,
                                        },
                                    },
                                },
                                "& .globalForm-fieldsRadio": {
                                    "marginBottom": theme.spacing(2),
                                },
                                "& .globalForm-actionContainer": {
                                },
                                "& .globalForm-fieldsTitle": {
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .globalForm-formButton": {
                                    "width": "100%",
                                },
                            }),
                        },
                    },
                },
            },
        },
        "newDocumentBtn": { // options: src/components/MyAccount/property/components/reportFaultBtn/defaults.js
            "props": {
            },
            "subthemes": {
                "dialogChildren": {
                    "subthemes": {
                        "globalForm": { // Overriding how the globalForm renders in action dialog
                            "props": {
                                "formButton": {
                                    "variant": "contained",
                                    "color": "primary",
                                    "fullWidth": true,
                                },
                            },
                            "sx": (theme) => ({
                                "& .globalForm-globalFormForm": {
                                    "width": "100%",
                                },
                                "& .globalForm-fieldContainer": {
                                    "display": "flex",
                                    "flexDirection": "column",
                                },
                                "& .globalForm-textField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(2.375),
                                },
                                "& .globalForm-selectField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .react-datepicker": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(3),
                                    "& .react-datepicker__time-container": {
                                        "& li.react-datepicker__time-list-item": {
                                            "display": "flex",
                                            "alignItems": "center",
                                            "&.react-datepicker__time-list-item--disabled": {
                                                "display": "none",
                                            },
                                        },
                                        "& .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected": {
                                            "backgroundColor": theme.palette.primary.main,
                                        },
                                        "& .react-datepicker__day--selected": {
                                            "backgroundColor": theme.palette.primary.main,
                                            "color": "#fff",
                                            "&:hover": {
                                                "backgroundColor": theme.palette.primary.main,
                                            },
                                        },
                                    },
                                },
                                "& .suggestionsContainer": {
                                    "position": "relative",
                                    "& .suggestionsContainerOpen": {
                                        "position": "absolute",
                                        "zIndex": 998,
                                        "top": "50px",
                                        "left": 0,
                                        "right": 0,
                                    },
                                    "& .suggestionsList": {
                                        "margin": 0,
                                        "padding": 0,
                                        "listStyleType": "none",
                                    },
                                    "& .suggestion": {
                                        "display": "block",
                                        "padding": "10px 15px",
                                        "&:hover": {
                                            "cursor": "pointer",
                                            "background": theme.palette.grey.grey3,
                                        },
                                    },
                                },
                                "& .globalForm-fieldsRadio": {
                                    "marginBottom": theme.spacing(2),
                                },
                                "& .globalForm-actionContainer": {
                                },
                                "& .globalForm-fieldsTitle": {
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .globalForm-formButton": {
                                    "width": "100%",
                                },
                            }),
                        },
                    },
                },
            },
        },
        "simpleValuationModule": {
            "subthemes": {
                "dialogChildren": {
                    "subthemes": {
                        "globalForm": { // Overriding how the globalForm renders in action dialog
                            "props": {
                                "formButton": {
                                    "variant": "contained",
                                    "color": "primary",
                                    "fullWidth": true,
                                },
                            },
                            "sx": (theme) => ({
                                "& .globalForm-globalFormForm": {
                                    "width": "100%",
                                },
                                "& .globalForm-fieldContainer": {
                                    "display": "flex",
                                    "flexDirection": "column",
                                },
                                "& .globalForm-textField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(2.375),
                                },
                                "& .globalForm-selectField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .react-datepicker": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(3),
                                    "& .react-datepicker__time-container": {
                                        "& li.react-datepicker__time-list-item": {
                                            "display": "flex",
                                            "alignItems": "center",
                                            "&.react-datepicker__time-list-item--disabled": {
                                                "display": "none",
                                            },
                                        },
                                        "& .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected": {
                                            "backgroundColor": theme.palette.primary.main,
                                        },
                                        "& .react-datepicker__day--selected": {
                                            "backgroundColor": theme.palette.primary.main,
                                            "color": "#fff",
                                            "&:hover": {
                                                "backgroundColor": theme.palette.primary.main,
                                            },
                                        },
                                    },
                                },
                                "& .suggestionsContainer": {
                                    "position": "relative",
                                    "& .suggestionsContainerOpen": {
                                        "position": "absolute",
                                        "zIndex": 998,
                                        "top": "50px",
                                        "left": 0,
                                        "right": 0,
                                    },
                                    "& .suggestionsList": {
                                        "margin": 0,
                                        "padding": 0,
                                        "listStyleType": "none",
                                    },
                                    "& .suggestion": {
                                        "display": "block",
                                        "padding": "10px 15px",
                                        "&:hover": {
                                            "cursor": "pointer",
                                            "background": theme.palette.grey.grey3,
                                        },
                                    },
                                },
                                "& .globalForm-fieldsRadio": {
                                    "marginBottom": theme.spacing(2),
                                },
                                "& .globalForm-actionContainer": {
                                },
                                "& .globalForm-fieldsTitle": {
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .globalForm-formButton": {
                                    "width": "100%",
                                },
                            }),
                        },
                    },
                },
            },
        },
        "contactBtn": { // options: src/components/MyAccount/property/components/contactBtn/defaults.js
            "subthemes": {
                "dialogChildren": {
                    "subthemes": {
                        "globalForm": { // Overriding how the globalForm renders in action dialog
                            "props": {
                                "formButton": {
                                    "variant": "contained",
                                    "color": "primary",
                                    "fullWidth": true,
                                },
                            },
                            "sx": (theme) => ({
                                "& .globalForm-globalFormForm": {
                                    "width": "100%",
                                },
                                "& .globalForm-fieldContainer": {
                                    "display": "flex",
                                    "flexDirection": "column",
                                },
                                "& .globalForm-textField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(2.375),
                                },
                                "& .globalForm-selectField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .react-datepicker": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(3),
                                    "& .react-datepicker__time-container": {
                                        "& li.react-datepicker__time-list-item": {
                                            "display": "flex",
                                            "alignItems": "center",
                                            "&.react-datepicker__time-list-item--disabled": {
                                                "display": "none",
                                            },
                                        },
                                        "& .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected": {
                                            "backgroundColor": theme.palette.primary.main,
                                        },
                                        "& .react-datepicker__day--selected": {
                                            "backgroundColor": theme.palette.primary.main,
                                            "color": "#fff",
                                            "&:hover": {
                                                "backgroundColor": theme.palette.primary.main,
                                            },
                                        },
                                    },
                                },
                                "& .suggestionsContainer": {
                                    "position": "relative",
                                    "& .suggestionsContainerOpen": {
                                        "position": "absolute",
                                        "zIndex": 998,
                                        "top": "50px",
                                        "left": 0,
                                        "right": 0,
                                    },
                                    "& .suggestionsList": {
                                        "margin": 0,
                                        "padding": 0,
                                        "listStyleType": "none",
                                    },
                                    "& .suggestion": {
                                        "display": "block",
                                        "padding": "10px 15px",
                                        "&:hover": {
                                            "cursor": "pointer",
                                            "background": theme.palette.grey.grey3,
                                        },
                                    },
                                },
                                "& .globalForm-fieldsRadio": {
                                    "marginBottom": theme.spacing(2),
                                },
                                "& .globalForm-actionContainer": {
                                },
                                "& .globalForm-fieldsTitle": {
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .globalForm-formButton": {
                                    "width": "100%",
                                },
                            }),
                        },
                    },
                },
            },
        },
        "makeOfferBtn": { // options: src/components/MyAccount/property/components/makeOffer/defaults.js
            "subthemes": {
                "dialogChildren": {
                    "subthemes": {
                        "globalForm": { // Overriding how the globalForm renders in action dialog
                            "props": {
                                "formButton": {
                                    "variant": "contained",
                                    "color": "primary",
                                    "fullWidth": true,
                                },
                            },
                            "sx": (theme) => ({
                                "& .globalForm-globalFormForm": {
                                    "width": "100%",
                                },
                                "& .globalForm-fieldContainer": {
                                    "display": "flex",
                                    "flexDirection": "column",
                                },
                                "& .globalForm-textField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(2.375),
                                },
                                "& .globalForm-selectField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .react-datepicker": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(3),
                                    "& .react-datepicker__time-container": {
                                        "& li.react-datepicker__time-list-item": {
                                            "display": "flex",
                                            "alignItems": "center",
                                            "&.react-datepicker__time-list-item--disabled": {
                                                "display": "none",
                                            },
                                        },
                                        "& .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected": {
                                            "backgroundColor": theme.palette.primary.main,
                                        },
                                        "& .react-datepicker__day--selected": {
                                            "backgroundColor": theme.palette.primary.main,
                                            "color": "#fff",
                                            "&:hover": {
                                                "backgroundColor": theme.palette.primary.main,
                                            },
                                        },
                                    },
                                },
                                "& .suggestionsContainer": {
                                    "position": "relative",
                                    "& .suggestionsContainerOpen": {
                                        "position": "absolute",
                                        "zIndex": 998,
                                        "top": "50px",
                                        "left": 0,
                                        "right": 0,
                                    },
                                    "& .suggestionsList": {
                                        "margin": 0,
                                        "padding": 0,
                                        "listStyleType": "none",
                                    },
                                    "& .suggestion": {
                                        "display": "block",
                                        "padding": "10px 15px",
                                        "&:hover": {
                                            "cursor": "pointer",
                                            "background": theme.palette.grey.grey3,
                                        },
                                    },
                                },
                                "& .globalForm-fieldsRadio": {
                                    "marginBottom": theme.spacing(2),
                                },
                                "& .globalForm-actionContainer": {
                                },
                                "& .globalForm-fieldsTitle": {
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .globalForm-formButton": {
                                    "width": "100%",
                                },
                            }),
                        },
                    },
                },
            },
        },
        "bookViewingBtn": { // options: src/components/MyAccount/property/components/bookViewing/defaults.js
            "subthemes": {
                "dialogChildren": {
                    "subthemes": {
                        "globalForm": { // Overriding how the globalForm renders in action dialog
                            "props": {
                                "formButton": {
                                    "variant": "contained",
                                    "color": "primary",
                                    "fullWidth": true,
                                },
                            },
                            "sx": (theme) => ({
                                "& .globalForm-globalFormForm": {
                                    "width": "100%",
                                },
                                "& .globalForm-fieldContainer": {
                                    "display": "flex",
                                    "flexDirection": "column",
                                },
                                "& .globalForm-textField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(2.375),
                                },
                                "& .globalForm-selectField": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .react-datepicker": {
                                    "width": "100%",
                                    "marginBottom": theme.spacing(3),
                                    "& .react-datepicker__time-container": {
                                        "& li.react-datepicker__time-list-item": {
                                            "display": "flex",
                                            "alignItems": "center",
                                            "&.react-datepicker__time-list-item--disabled": {
                                                "display": "none",
                                            },
                                        },
                                        "& .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected": {
                                            "backgroundColor": theme.palette.primary.main,
                                        },
                                        "& .react-datepicker__day--selected": {
                                            "backgroundColor": theme.palette.primary.main,
                                            "color": "#fff",
                                            "&:hover": {
                                                "backgroundColor": theme.palette.primary.main,
                                            },
                                        },
                                    },
                                },
                                "& .suggestionsContainer": {
                                    "position": "relative",
                                    "& .suggestionsContainerOpen": {
                                        "position": "absolute",
                                        "zIndex": 998,
                                        "top": "50px",
                                        "left": 0,
                                        "right": 0,
                                    },
                                    "& .suggestionsList": {
                                        "margin": 0,
                                        "padding": 0,
                                        "listStyleType": "none",
                                    },
                                    "& .suggestion": {
                                        "display": "block",
                                        "padding": "10px 15px",
                                        "&:hover": {
                                            "cursor": "pointer",
                                            "background": theme.palette.grey.grey3,
                                        },
                                    },
                                },
                                "& .globalForm-fieldsRadio": {
                                    "marginBottom": theme.spacing(2),
                                },
                                "& .globalForm-actionContainer": {
                                },
                                "& .globalForm-fieldsTitle": {
                                    "marginBottom": theme.spacing(3),
                                },
                                "& .globalForm-formButton": {
                                    "width": "100%",
                                },
                            }),
                        },
                    },
                },
            },
        },
        "removePropertyPersona": { // options: src/components/MyAccount/property/components/removePropertyPersona/defaults.js
            "subthemes": {
                "dialogDefault": {
                    "props": {
                        "dialogTitleProps": {
                            "align": "left"
                        },
                    },
                    "sx": (theme) => ({
                        "& .dialogDefault-container": {
                        },
                        "& .dialogDefault-paper": {
                            [theme.breakpoints.up('md')]: {
                                "borderRadius": "6px",
                            },
                            "& .MuiDialogTitle-root": { // This is the dialog title container
                                "& .MuiTypography-root": { // This is the title
                                    "marginRight": "20px",
                                    "textAlign": "center",
                                },
                            },
                        },
                        "& .dialogDefault-paperFullScreen": {
                            "borderRadius": "6px 6px 0 0",
                        },
                        "& .dialogDefault-close": {
                            "position": "absolute",
                            "top": "20px",
                            "right": "20px",
                            "width": "24px",
                            "height": "24px",
                            "zIndex": 9999,
                        },
                        "& .dialogDefault-content": {}
                    })
                }
            }
        },
        "homeReportBtn": { // options: src/components/MyAccount/property/components/homeReport/defaults.js
        },
        "floatingContainer": { // options: src/components/MyAccount/floatingContainer/defaults.js
        },
        "fabBtn": { // options: src/components/MyAccount/fabBtn/defaults.js
            "props": {
                "containerText": "Looking to buy or rent property in Essex and Kent? Save your property preference and we will email you a list of properties that match your search criteria so that you do not miss out on the action.",
                "defaultArea": defaultArea,
                "defaultSearchType": "",
            },
            "subthemes": {
                "globalForm": { // Overriding how the globalForm renders in action dialog
                    "props": {
                        "formButton": {
                            "variant": "contained",
                            "color": "secondary",
                            "fullWidth": true,
                        },
                    },
                    "sx": (theme) => ({
                        "& .globalForm-globalFormForm": {
                            "width": "100%",
                        },

                        "& .globalForm-fieldContainer": {
                            "display": "flex",
                            "flexDirection": "column",
                            "marginTop": theme.spacing(2),
                            "fontFamily": theme.secondaryFontFamily,
                        },
                        "& .globalForm-textField": {
                            "width": "100%",
                            "marginBottom": theme.spacing(2.375),
                        },
                        "& .globalForm-selectField": {
                            "width": "100%",
                            "marginBottom": theme.spacing(3),
                        },
                        "& .globalForm-jsonAutocompleteField": {
                            "width": "100%",
                            "marginBottom": theme.spacing(3),
                            "& .MuiInputLabel-root": {
                                // "transform": "translate(14px, 9px) scale(1)",
                                "color": theme.palette.grey.grey1,
                                "fontSize": "14px"
                            },
                        },
                        "& .react-datepicker": {
                            "width": "100%",
                            "marginBottom": theme.spacing(3),
                            "& .react-datepicker__time-container": {
                                "& li.react-datepicker__time-list-item": {
                                    "display": "flex",
                                    "alignItems": "center",
                                    "&.react-datepicker__time-list-item--disabled": {
                                        "display": "none",
                                    },
                                },
                                "& .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected": {
                                    "backgroundColor": theme.palette.primary.main,
                                },
                                "& .react-datepicker__day--selected": {
                                    "backgroundColor": theme.palette.primary.main,
                                    "color": "#fff",
                                    "&:hover": {
                                        "backgroundColor": theme.palette.primary.main,
                                    },
                                },
                            },
                        },
                        "& .suggestionsContainer": {
                            "position": "relative",
                            "& .suggestionsContainerOpen": {
                                "position": "absolute",
                                "zIndex": 998,
                                "top": "50px",
                                "left": 0,
                                "right": 0,
                            },
                            "& .suggestionsList": {
                                "margin": 0,
                                "padding": 0,
                                "listStyleType": "none",
                            },
                            "& .suggestion": {
                                "display": "block",
                                "padding": "10px 15px",
                                "&:hover": {
                                    "cursor": "pointer",
                                    "background": theme.palette.grey.grey3,
                                },
                            },
                        },
                        "& .globalForm-fieldsRadio": {
                            "marginBottom": theme.spacing(2),
                        },
                        "& .globalForm-actionContainer": {
                        },
                        "& .globalForm-fieldsTitle": {
                            "marginBottom": theme.spacing(3),
                            "color": "#3D3D3D",
                            "fontFamily": theme.secondaryFontFamily,
                        },
                        "& .globalForm-formButton": {
                        },
                    }),
                },
            },
        },
        "currentTenancy": { // options: src/components/MyAccount/currentTenancy/defaults.js
        },
        "payments": { // options: src/components/MyAccount/payments/defaults.js
        },
        "newsBannerImage": { // options: src/components/MyAccount/blog/components/bannerImage/defaults.js
        },
        "homePage": {
            "subthemes": {
                "tabsMyAccount": { // override styling for tabs on homepage subtheme
                    "sx": (theme) => ({
                        "flexGrow": 1,
                        "marginBottom": theme.moduleSpacing,
                        [theme.breakpoints.up('sm')]: {
                            "marginBottom": theme.moduleSpacingSm,
                        },
                        [theme.breakpoints.up('lg')]: {
                            "marginBottom": theme.moduleSpacingLg,
                        },
                        // "backgroundColor": theme.palette.background.paper,
                        "& .tabsMyAccount-tabs": {
                            "borderBottom": "1px solid #d8d8d8",
                            "marginLeft": `-${theme.gridItemPmobile}`,
                            "marginRight": `-${theme.gridItemPmobile}`,
                            [theme.breakpoints.up('sm')]: {
                                "marginLeft": `-${theme.gridItemPtablet}`,
                                "marginRight": `-${theme.gridItemPtablet}`,
                                "paddingLeft": theme.gridItemPtablet,
                                "paddingRight": theme.gridItemPtablet,
                            },
                            [theme.breakpoints.up('lg')]: {
                                "borderBottom": "none",
                                "marginLeft": `calc(-${theme.gridItemPdesktop} * 2)`,
                                "marginRight": `calc(-${theme.gridItemPdesktop} * 2)`,
                                "paddingLeft": `calc(${theme.gridItemPdesktop} * 2)`,
                                "paddingRight": `calc(${theme.gridItemPdesktop} * 2)`,
                            },
                            "& .tabsMyAccount-indicator": {
                                "height": "3px",
                                "backgroundColor": theme.palette.secondary.main,
                            },
                            "& .tabsMyAccount-scrollButtons": {
                                "alignItems": "flex-start",
                                "& svg": {
                                    "marginTop": "12px",
                                },
                                "&.Mui-disabled": {
                                    "opacity": 0.3,
                                },
                            },
                        },
                        "& .tabsMyAccount-tab": {
                            "textTransform": "none",
                            "fontFamily": theme.secondaryFontFamily,
                            "color": theme.palette.grey.grey1,
                            "opacity": 0.4,
                            [theme.breakpoints.up('sm')]: {
                                "minWidth": "auto",
                                "paddingLeft": 0,
                                "paddingRight": 0,
                                "marginRight": theme.spacing(5),
                            },
                            "&.tabsMyAccount-selected": {
                                "color": theme.palette.common.black,
                                "opacity": 1,
                            },
                            "& .tabsMyAccount-wrapper": {
                                "fontSize": theme.bodySmall,
                                [theme.breakpoints.up('sm')]: {
                                    "fontSize": theme.typography.h5.fontSize,
                                }
                            },
                        },
                        "& .tabsMyAccount-tabPanel": {
                            "padding": 0,
                        },
                        "& .tabsMyAccount-tabPanelBox": {
                            "padding": theme.spacing(2.75, 0, 0, 0),
                        }
                    }),
                },
            },
        },
        "homePage2": {
            "subthemes": {
                "tabsMyAccount": { // override styling for tabs on homepage subtheme
                    "sx": (theme) => ({
                        "flexGrow": 1,
                        "marginBottom": theme.moduleSpacing,
                        [theme.breakpoints.up('sm')]: {
                            "marginBottom": theme.moduleSpacingSm,
                        },
                        [theme.breakpoints.up('lg')]: {
                            "marginBottom": theme.moduleSpacingLg,
                        },
                        // "backgroundColor": theme.palette.background.paper,
                        "& .tabsMyAccount-tabs": {
                            "borderBottom": "1px solid #d8d8d8",
                            "marginLeft": `-${theme.gridItemPmobile}`,
                            "marginRight": `-${theme.gridItemPmobile}`,
                            [theme.breakpoints.up('sm')]: {
                                "marginLeft": `-${theme.gridItemPtablet}`,
                                "marginRight": `-${theme.gridItemPtablet}`,
                                "paddingLeft": theme.gridItemPtablet,
                                "paddingRight": theme.gridItemPtablet,
                            },
                            [theme.breakpoints.up('lg')]: {
                                "borderBottom": "none",
                                "marginLeft": `calc(-${theme.gridItemPdesktop} * 2)`,
                                "marginRight": `calc(-${theme.gridItemPdesktop} * 2)`,
                                "paddingLeft": `calc(${theme.gridItemPdesktop} * 2)`,
                                "paddingRight": `calc(${theme.gridItemPdesktop} * 2)`,
                            },
                            "& .tabsMyAccount-indicator": {
                                "height": "3px",
                                "backgroundColor": theme.palette.secondary.main,
                            },
                            "& .tabsMyAccount-scrollButtons": {
                                "alignItems": "flex-start",
                                "& svg": {
                                    "marginTop": "12px",
                                },
                                "&.Mui-disabled": {
                                    "opacity": 0.3,
                                },
                            },
                        },
                        "& .tabsMyAccount-tab": {
                            "textTransform": "none",
                            "fontFamily": theme.secondaryFontFamily,
                            "color": theme.palette.grey.grey1,
                            "opacity": 0.4,
                            [theme.breakpoints.up('sm')]: {
                                "minWidth": "auto",
                                "paddingLeft": 0,
                                "paddingRight": 0,
                                "marginRight": theme.spacing(5),
                            },
                            "&.tabsMyAccount-selected": {
                                "color": theme.palette.common.black,
                                "opacity": 1,
                            },
                            "& .tabsMyAccount-wrapper": {
                                "fontSize": theme.bodySmall,
                                [theme.breakpoints.up('sm')]: {
                                    "fontSize": theme.typography.h5.fontSize,
                                }
                            },
                        },
                        "& .tabsMyAccount-tabPanel": {
                            "padding": 0,
                        },
                        "& .tabsMyAccount-tabPanelBox": {
                            "padding": theme.spacing(2.75, 0, 0, 0),
                        }
                    }),
                },
                "activityCard": {
                    "subthemes": {
                        "actionCard": {
                            "sx": (theme) => ({
                                "&.actionCard-card": {
                                    "marginBottom": 0,
                                    "paddingTop": theme.spacing(1),
                                    "paddingBottom": theme.spacing(1),
                                    "&.disabled": {
                                        "opacity": 0.5
                                    }
                                },
                                "& .actionCard-cardActionButton": {
                                    "backgroundColor": theme.palette.grey.grey4,
                                    "color": theme.palette.primary.dark,
                                },
                                "& .actionCard-cardHeader": {
                                    "padding": theme.spacing(1, 0),
                                    [theme.breakpoints.up('sm')]: {
                                        "padding": theme.spacing(1.5, 0),
                                    },
                                    "& .cardHeader-avatar": {
                                        "marginLeft": "auto",
                                        "marginRight": theme.spacing(1.875),
                                        [theme.breakpoints.up('sm')]: {
                                            "marginRight": theme.spacing(3.5),
                                        }
                                    },
                                    "& .cardHeader-title": {
                                        "fontSize": theme.bodySmall,
                                        "marginBottom": theme.spacing(0.25),
                                        [theme.breakpoints.up('sm')]: {
                                            "fontSize": theme.bodyLarge,
                                            "lineHeight": theme.lineHeightBLarge,
                                            "marginBottom": theme.spacing(0.5),
                                        },
                                        [theme.breakpoints.up('lg')]: {
                                            "fontSize": theme.bodySmall,
                                            "lineHeight": theme.lineHeightBSmall,
                                            "marginBottom": theme.spacing(0.25),
                                        },
                                    },
                                    "& .cardHeader-subheader": {
                                        "color": theme.palette.text.primary,
                                        [theme.breakpoints.up('sm')]: {
                                            "fontSize": theme.bodyMedium,
                                            "lineHeight": theme.lineHeightBMedium,
                                        },
                                        [theme.breakpoints.up('lg')]: {
                                            "fontSize": theme.bodyXSmall,
                                            "lineHeight": theme.lineHeightBXSmall,
                                        },
                                    },
                                    "& .cardHeader-action": {
                                        "alignSelf": "center",
                                        "marginRight": theme.spacing(0.5),
                                    }
                                },
                            })
                        },
                    },
                },
                // "propertyResultComponent": {
                //     "subthemes": {
                //         "results": { // default property result styling
                //             "sx": (theme) => ({
                //                 [theme.breakpoints.up('sm')]: {
                //                     "width": "auto",
                //                     "margin": theme.spacing(0,-0.75),
                //                 },
                //                 [theme.breakpoints.up('lg')]: {
                //                     "margin": theme.spacing(0,-2.375),
                //                 },
                //             }),
                //             "subthemes": {
                //                 "propertyresult": {
                //                     "props": {
                //                         "container": {
                //                             "order": ['media', 'header', 'aside'],
                //                             "sx": (theme) => ({
                //                                 "flexDirection": "row",
                //                                 "width": "100%",
                //                                 "paddingTop": theme.spacing(1.125),
                //                                 "paddingBottom": theme.spacing(1.125),
                //                                 [theme.breakpoints.up('sm')]: {
                //                                     "flexDirection": "column",
                //                                     "width": `calc(50% - ${theme.spacing(1.5)})`,
                //                                     "paddingTop": 0,
                //                                     "paddingBottom": 0,
                //                                     "marginBottom": theme.spacing(5),
                //                                     "marginLeft": theme.spacing(0.75),
                //                                     "marginRight": theme.spacing(0.75),
                //                                 },
                //                                 [theme.breakpoints.up('lg')]: {
                //                                     "width": `calc(33.33% - ${theme.spacing(4.75)})`,
                //                                     "marginLeft": theme.spacing(2.375),
                //                                     "marginRight": theme.spacing(2.375),
                //                                     "marginBottom": 0,
                //                                 },
                //                             }),
                //                         },
                //                         "header": {
                //                             "sx": (theme) => ({
                //                                 "width": "calc(100% - 110px)",
                //                                 [theme.breakpoints.up('sm')]: {
                //                                     "width": "100%",
                //                                     "marginTop": theme.spacing(3.25),
                //                                 },
                //                                 "& .propertyresult-address": {
                //                                     "fontWeight": "bold",
                //                                     [theme.breakpoints.up('sm')]: {
                //                                         "fontSize": theme.bodyLarge,
                //                                         "lineHeight": theme.lineHeightBLarge,
                //                                         "marginBottom": theme.spacing(0.25),
                //                                     },
                //                                 },
                //                                 "& .propertyresult-price": {
                //                                     [theme.breakpoints.up('sm')]: {
                //                                         "fontSize": theme.bodyMedium,
                //                                         "lineHeight": theme.lineHeightBMedium,
                //                                     },
                //                                 },
                //                             }),
                //                         },
                //                         "price": {
                //                             "in": "header",
                //                             "variant": "bodySmall",
                //                         },
                //                         "address": {
                //                             "in": "header",
                //                             "variant": "bodyMedium",
                //                             "component": "header",
                //                         },
                //                         "offer": {
                //                             "in": "header",
                //                             "variant": "bodyMedium",
                //                         },
                //                         "rooms": {
                //                             "in": null,
                //                         },
                //                         // "save": {
                //                         //     "in": "aside",
                //                         // },
                //                         // "removeProperty": {
                //                         //     "in": "aside",
                //                         // },
                //                         "media": {
                //                             "sx": (theme) => ({
                //                                 "overflow": "hidden",
                //                                 "marginRight": theme.spacing(2.375),
                //                                 "& .propertyresult-link-image": {
                //                                     "display": "block",
                //                                 },
                //                                 "& .propertyresult-image": {
                //                                     "display": "block",
                //                                     "width": "90px",
                //                                     [theme.breakpoints.up('sm')]: {
                //                                         "width": "100%",
                //                                     }
                //                                 },
                //                                 [theme.breakpoints.up('sm')]: {
                //                                     "marginRight": 0,
                //                                 }
                //                             })
                //                         },
                //                     },

                //                     // "typography": {
                //                     //     "h6": { "textTransform": "none" },
                //                     //     "subtitle1": { "variant": "bodyMedium", "textTransform": "none" },
                //                     //     "subtitle2": { "textTransform": "none" },
                //                     // },

                //                     // "address": {
                //                     //     "separator": " • ",
                //                     //     "values": (p) => [p.display_address,p.street_name,p.district,p.area].filter(x=>x).shift().toUpperCase() /*should really be CSS */
                //                     // },
                //                 },
                //             },
                //         },
                //     },
                // },
            },
        },
        "myPropertyPage": {
            "props": {
                "tab": {
                    "default": "wishlist",
                    // This can be a array or objects
                    "items": ['wishlist', 'saved-search'],
                }
            },
            "subthemes": {
                "tabsMyAccount": {
                    "sx": (theme) => ({
                        "flexGrow": 1,
                        "marginBottom": theme.moduleSpacing,
                        [theme.breakpoints.up('sm')]: {
                            "marginBottom": theme.moduleSpacingSm,
                        },
                        [theme.breakpoints.up('lg')]: {
                            "marginBottom": theme.moduleSpacingLg,
                        },
                        "& .tabsMyAccount-tabs": {
                            "borderBottom": "1px solid #d8d8d8",
                            "marginLeft": `-${theme.gridItemPmobile}`,
                            "marginRight": `-${theme.gridItemPmobile}`,
                            [theme.breakpoints.up('sm')]: {
                                "marginLeft": `-${theme.gridItemPtablet}`,
                                "marginRight": `-${theme.gridItemPtablet}`,
                                "paddingLeft": theme.gridItemPtablet,
                                "paddingRight": theme.gridItemPtablet,
                            },
                            [theme.breakpoints.up('lg')]: {
                                "marginLeft": `calc(-${theme.gridItemPdesktop} * 2)`,
                                "marginRight": `calc(-${theme.gridItemPdesktop} * 2)`,
                                "paddingLeft": `calc(${theme.gridItemPdesktop} * 2)`,
                                "paddingRight": `calc(${theme.gridItemPdesktop} * 2)`,
                            },
                            "& .tabsMyAccount-indicator": {
                                "height": "3px",
                                "backgroundColor": theme.palette.secondary.main,
                            },
                            "& .tabsMyAccount-scrollButtons": {
                                "alignItems": "flex-start",
                                "& svg": {
                                    "marginTop": "12px",
                                },
                                "&.Mui-disabled": {
                                    "opacity": 0.3,
                                },
                            },
                        },
                        "& .tabsMyAccount-tab": {
                            "fontFamily": theme.secondaryFontFamily,
                            "textTransform": "none",
                            "minWidth": "50%",
                            [theme.breakpoints.up('sm')]: {
                                "minWidth": "auto",
                                "paddingLeft": 0,
                                "paddingRight": 0,
                                "marginRight": theme.spacing(5),
                                "fontSize": "22px",
                                "lineHeight": "32px"
                            },
                            [theme.breakpoints.up('md')]: {
                                "paddingBottom": theme.spacing(3.5),
                            },
                            "&.tabsMyAccount-selected": {
                                "color": theme.palette.common.black,
                            },
                            "& .tabsMyAccount-wrapper": {
                                "fontSize": theme.bodySmall,
                                [theme.breakpoints.up('sm')]: {
                                    "fontSize": theme.bodyMedium,
                                    "lineHeight": theme.lineHeightBMedium,
                                },
                                [theme.breakpoints.up('md')]: {
                                    "fontSize": theme.bodyXLarge,
                                    "lineHeight": theme.lineHeightBXLarge,
                                },
                            },
                        },
                        "& .tabsMyAccount-tabPanel": {
                            "padding": 0,
                        },
                        "& .tabsMyAccount-tabPanelBox": {
                            "padding": theme.spacing(2.75, 0, 0, 0),
                            [theme.breakpoints.up('sm')]: {
                                "padding": theme.spacing(5, 0, 0, 0),
                            },
                        }
                    }),
                },
            },
        },
        "myPropertyPageSeller": {
            "subthemes": {
                "mainContent": { // Remove top content spacing on mobile
                    "sx": (theme) => ({
                        "display": "flex",
                        "flexDirection": "column",
                        "width": "100%",
                        "flexGrow": 1,
                        "padding": theme.spacing(0, 0, (theme.myAccountContentPBmobile + 8), 0),
                        [theme.breakpoints.up('sm')]: {
                            "padding": theme.spacing(0, 0, (theme.myAccountContentPBtablet + 8), 0),
                        },
                        [theme.breakpoints.up('md')]: {
                            // "width": `calc(100% - ${theme.myAccountSetDrawerWidth}px)`,
                            "padding": theme.spacing(theme.myAccountContentPTtablet, 0, theme.myAccountContentPBtablet, 0),
                        },
                        [theme.breakpoints.up('lg')]: {
                            "padding": theme.spacing(theme.myAccountContentPTDesktop, 0, theme.myAccountContentPBDesktop, 0),
                        },
                        "& .mainContent-wrapper": {
                            "flexGrow": 1,
                        },
                        "& .mainContent-toolbar": theme.mixins.toolbar,
                    }),
                },
                "tabsMyAccount": {
                    "sx": (theme) => ({
                        "flexGrow": 1,
                        "marginBottom": theme.moduleSpacing,
                        [theme.breakpoints.up('sm')]: {
                            "marginBottom": theme.moduleSpacingSm,
                        },
                        [theme.breakpoints.up('lg')]: {
                            "marginBottom": theme.moduleSpacingLg,
                        },
                        // "backgroundColor": theme.palette.background.paper,
                        "& .tabsMyAccount-tabs": {
                            "display": "none",
                            "borderBottom": "1px solid #d8d8d8",
                            "marginLeft": `-${theme.gridItemPmobile}`,
                            "marginRight": `-${theme.gridItemPmobile}`,
                            "marginTop": `-${theme.spacing(4)}`, // we need to have negative margin on mobile to pull the 2 tabs bellow the image carousel
                            [theme.breakpoints.up('sm')]: {
                                "marginLeft": `-${theme.gridItemPtablet}`,
                                "marginRight": `-${theme.gridItemPtablet}`,
                                "paddingLeft": theme.gridItemPtablet,
                                "paddingRight": theme.gridItemPtablet,
                                "marginTop": 0,
                            },
                            [theme.breakpoints.up('md')]: {
                                "display": "flex",
                            },
                            [theme.breakpoints.up('lg')]: {
                                "marginLeft": `calc(-${theme.gridItemPdesktop} * 2)`,
                                "marginRight": `calc(-${theme.gridItemPdesktop} * 2)`,
                                "paddingLeft": `calc(${theme.gridItemPdesktop} * 2)`,
                                "paddingRight": `calc(${theme.gridItemPdesktop} * 2)`,
                            },
                            "& .tabsMyAccount-indicator": {
                                "height": "3px",
                                "backgroundColor": theme.palette.secondary.main,
                            },
                            "& .tabsMyAccount-scrollButtons": {
                                "alignItems": "flex-start",
                                "& svg": {
                                    "marginTop": "12px",
                                },
                                "&.Mui-disabled": {
                                    "opacity": 0.3,
                                },
                            },
                        },
                        "& .tabsMyAccount-tab": {
                            "fontFamily": theme.primaryFontFamily,
                            "textTransform": "none",
                            [theme.breakpoints.up('sm')]: {
                                "minWidth": "auto",
                                "paddingLeft": 0,
                                "paddingRight": 0,
                                "marginRight": theme.spacing(5),
                            },
                            [theme.breakpoints.up('md')]: {
                                "paddingBottom": theme.spacing(3.5),
                            },
                            "&.tabsMyAccount-selected": {
                                "color": theme.palette.common.black,
                            },
                            "& .tabsMyAccount-wrapper": {
                                "fontSize": theme.bodySmall,
                                [theme.breakpoints.up('sm')]: {
                                    "fontSize": theme.bodyLarge,
                                }
                            },
                        },
                        "& .tabsMyAccount-tabPanel": {
                            "padding": 0,
                        },
                        "& .tabsMyAccount-tabPanelBox": {
                            "padding": theme.spacing(0, 0, 0, 0),
                            [theme.breakpoints.up('md')]: {
                                "padding": theme.spacing(7, 0, 0, 0),
                            },
                        }
                    }),
                    "subthemes": {
                        "tabsMyAccount": {
                            "sx": (theme) => ({
                                "flexGrow": 1,
                                "marginBottom": theme.moduleSpacing,
                                [theme.breakpoints.up('sm')]: {
                                    "marginBottom": theme.moduleSpacingSm,
                                },
                                [theme.breakpoints.up('lg')]: {
                                    "marginBottom": theme.moduleSpacingLg,
                                },
                                // "backgroundColor": theme.palette.background.paper,
                                "& .tabsMyAccount-tabs": {
                                    "display": "flex",
                                    "borderBottom": "1px solid #d8d8d8",
                                    "marginLeft": `-${theme.gridItemPmobile}`,
                                    "marginRight": `-${theme.gridItemPmobile}`,
                                    [theme.breakpoints.up('sm')]: {
                                        "marginLeft": `-${theme.gridItemPtablet}`,
                                        "marginRight": `-${theme.gridItemPtablet}`,
                                        "paddingLeft": theme.gridItemPtablet,
                                        "paddingRight": theme.gridItemPtablet,
                                    },
                                    [theme.breakpoints.up('md')]: {
                                        "borderBottom": "none",
                                    },
                                    [theme.breakpoints.up('lg')]: {
                                        "marginLeft": `calc(-${theme.gridItemPdesktop} * 2)`,
                                        "marginRight": `calc(-${theme.gridItemPdesktop} * 2)`,
                                        "paddingLeft": `calc(${theme.gridItemPdesktop} * 2)`,
                                        "paddingRight": `calc(${theme.gridItemPdesktop} * 2)`,
                                    },
                                    "& .tabsMyAccount-indicator": {
                                        "height": "3px",
                                        // "backgroundColor": theme.palette.primary.main,
                                    },
                                    "& .tabsMyAccount-scrollButtons": {
                                        "alignItems": "flex-start",
                                        "& svg": {
                                            "marginTop": "12px",
                                        },
                                        "&.Mui-disabled": {
                                            "opacity": 0.3,
                                        },
                                    },
                                },
                                "& .tabsMyAccount-tab": {
                                    "textTransform": "none",
                                    [theme.breakpoints.up('sm')]: {
                                        "minWidth": "auto",
                                        "paddingLeft": 0,
                                        "paddingRight": 0,
                                        "marginRight": theme.spacing(5),
                                    },
                                    [theme.breakpoints.up('md')]: {
                                        "paddingBottom": theme.spacing(0.75),
                                    },
                                    "&.tabsMyAccount-selected": {
                                        "fontWeight": theme.typography.fontWeightBold,
                                    },
                                    "& .tabsMyAccount-wrapper": {
                                        "fontSize": theme.bodySmall,
                                        [theme.breakpoints.up('sm')]: {
                                            "fontSize": theme.typography.h5.fontSize,
                                        }
                                    },
                                },
                                "& .tabsMyAccount-tabPanel": {
                                    "padding": 0,
                                },
                                "& .tabsMyAccount-tabPanelBox": {
                                    "padding": theme.spacing(2.75, 0, 0, 0),
                                    [theme.breakpoints.up('md')]: {
                                        "padding": theme.spacing(2.75, 0, 0, 0),
                                    },
                                }
                            }),
                        },
                    },
                },
            },
        },
        "myPropertyPageLandlord": {
            "subthemes": {
                "mainContent": { // Remove top content spacing on mobile
                    "sx": (theme) => ({
                        "display": "flex",
                        "flexDirection": "column",
                        "width": "100%",
                        "flexGrow": 1,
                        "padding": theme.spacing(0, 0, (theme.myAccountContentPBmobile + 8), 0),
                        [theme.breakpoints.up('sm')]: {
                            "padding": theme.spacing(0, 0, (theme.myAccountContentPBtablet + 8), 0),
                        },
                        [theme.breakpoints.up('md')]: {
                            "width": `calc(100% - ${theme.myAccountSetDrawerWidth}px)`, // TODO REMOVE THIS WHEN WE USE GLOBALLAYOUT ON PROP DETAILS PAGE; THERE WE DON'T NEED CALC WIDTH AS ITS 100%
                            "padding": theme.spacing(theme.myAccountContentPTtablet, 0, theme.myAccountContentPBtablet, 0),
                        },
                        [theme.breakpoints.up('lg')]: {
                            "padding": theme.spacing(theme.myAccountContentPTDesktop, 0, theme.myAccountContentPBDesktop, 0),
                        },
                        "& .mainContent-wrapper": {
                            "flexGrow": 1,
                        },
                        "& .mainContent-toolbar": theme.mixins.toolbar,
                    }),
                },
                "tabsMyAccount": {
                    "sx": (theme) => ({
                        "flexGrow": 1,
                        "marginBottom": theme.moduleSpacing,
                        [theme.breakpoints.up('sm')]: {
                            "marginBottom": theme.moduleSpacingSm,
                        },
                        [theme.breakpoints.up('lg')]: {
                            "marginBottom": theme.moduleSpacingLg,
                        },
                        // "backgroundColor": theme.palette.background.paper,
                        "& .tabsMyAccount-tabs": {
                            "display": "none",
                            "borderBottom": "1px solid #d8d8d8",
                            "marginLeft": `-${theme.gridItemPmobile}`,
                            "marginRight": `-${theme.gridItemPmobile}`,
                            "marginTop": `-${theme.spacing(4)}`, // we need to have negative margin on mobile to pull the 2 tabs bellow the image carousel
                            [theme.breakpoints.up('sm')]: {
                                "marginLeft": `-${theme.gridItemPtablet}`,
                                "marginRight": `-${theme.gridItemPtablet}`,
                                "paddingLeft": theme.gridItemPtablet,
                                "paddingRight": theme.gridItemPtablet,
                                "marginTop": 0,
                            },
                            [theme.breakpoints.up('md')]: {
                                "display": "flex",
                            },
                            [theme.breakpoints.up('lg')]: {
                                "marginLeft": `calc(-${theme.gridItemPdesktop} * 2)`,
                                "marginRight": `calc(-${theme.gridItemPdesktop} * 2)`,
                                "paddingLeft": `calc(${theme.gridItemPdesktop} * 2)`,
                                "paddingRight": `calc(${theme.gridItemPdesktop} * 2)`,
                            },
                            "& .tabsMyAccount-indicator": {
                                "height": "3px",
                                "backgroundColor": theme.palette.secondary.main,
                            },
                            "& .tabsMyAccount-scrollButtons": {
                                "alignItems": "flex-start",
                                "& svg": {
                                    "marginTop": "12px",
                                },
                                "&.Mui-disabled": {
                                    "opacity": 0.3,
                                },
                            },
                        },
                        "& .tabsMyAccount-tab": {
                            "fontFamily": theme.primaryFontFamily,
                            "textTransform": "none",
                            [theme.breakpoints.up('sm')]: {
                                "minWidth": "auto",
                                "paddingLeft": 0,
                                "paddingRight": 0,
                                "marginRight": theme.spacing(5),
                            },
                            [theme.breakpoints.up('md')]: {
                                "paddingBottom": theme.spacing(3.5),
                            },
                            "&.tabsMyAccount-selected": {
                                "color": theme.palette.common.black,
                            },
                            "& .tabsMyAccount-wrapper": {
                                "fontSize": theme.bodySmall,
                                [theme.breakpoints.up('sm')]: {
                                    "fontSize": theme.bodyLarge,
                                }
                            },
                        },
                        "& .tabsMyAccount-tabPanel": {
                            "padding": 0,
                        },
                        "& .tabsMyAccount-tabPanelBox": {
                            "padding": theme.spacing(0, 0, 0, 0),
                            [theme.breakpoints.up('md')]: {
                                "padding": theme.spacing(7, 0, 0, 0),
                            },
                        }
                    }),
                    "subthemes": {
                        "tabsMyAccount": {
                            "sx": (theme) => ({
                                "flexGrow": 1,
                                "marginBottom": theme.moduleSpacing,
                                [theme.breakpoints.up('sm')]: {
                                    "marginBottom": theme.moduleSpacingSm,
                                },
                                [theme.breakpoints.up('lg')]: {
                                    "marginBottom": theme.moduleSpacingLg,
                                },
                                // "backgroundColor": theme.palette.background.paper,
                                "& .tabsMyAccount-tabs": {
                                    "display": "flex",
                                    "borderBottom": "1px solid #d8d8d8",
                                    "marginLeft": `-${theme.gridItemPmobile}`,
                                    "marginRight": `-${theme.gridItemPmobile}`,
                                    [theme.breakpoints.up('sm')]: {
                                        "marginLeft": `-${theme.gridItemPtablet}`,
                                        "marginRight": `-${theme.gridItemPtablet}`,
                                        "paddingLeft": theme.gridItemPtablet,
                                        "paddingRight": theme.gridItemPtablet,
                                    },
                                    [theme.breakpoints.up('md')]: {
                                        "borderBottom": "none",
                                    },
                                    [theme.breakpoints.up('lg')]: {
                                        "marginLeft": `calc(-${theme.gridItemPdesktop} * 2)`,
                                        "marginRight": `calc(-${theme.gridItemPdesktop} * 2)`,
                                        "paddingLeft": `calc(${theme.gridItemPdesktop} * 2)`,
                                        "paddingRight": `calc(${theme.gridItemPdesktop} * 2)`,
                                    },
                                    "& .tabsMyAccount-indicator": {
                                        "height": "3px",
                                        // "backgroundColor": theme.palette.primary.main,
                                    },
                                    "& .tabsMyAccount-scrollButtons": {
                                        "alignItems": "flex-start",
                                        "& svg": {
                                            "marginTop": "12px",
                                        },
                                        "&.Mui-disabled": {
                                            "opacity": 0.3,
                                        },
                                    },
                                },
                                "& .tabsMyAccount-tab": {
                                    "textTransform": "none",
                                    [theme.breakpoints.up('sm')]: {
                                        "minWidth": "auto",
                                        "paddingLeft": 0,
                                        "paddingRight": 0,
                                        "marginRight": theme.spacing(5),
                                    },
                                    [theme.breakpoints.up('md')]: {
                                        "paddingBottom": theme.spacing(0.75),
                                    },
                                    "&.tabsMyAccount-selected": {
                                        "fontWeight": theme.typography.fontWeightBold,
                                    },
                                    "& .tabsMyAccount-wrapper": {
                                        "fontSize": theme.bodySmall,
                                        [theme.breakpoints.up('sm')]: {
                                            "fontSize": theme.typography.h5.fontSize,
                                        }
                                    },
                                },
                                "& .tabsMyAccount-tabPanel": {
                                    "padding": 0,
                                },
                                "& .tabsMyAccount-tabPanelBox": {
                                    "padding": theme.spacing(2.75, 0, 0, 0),
                                    [theme.breakpoints.up('md')]: {
                                        "padding": theme.spacing(2.75, 0, 0, 0),
                                    },
                                }
                            }),
                        },
                    },
                },
            },
        },
        "calendarPage": {
        },
        "inbox": {
        },
        "loginPage": {
        },
        "signUpPage": {
        },
        "unsubscribePage": {
        },
        "resetPassEmailPage": {
        },
        "resetPassPage": {
        },
        "profilePage": {
            "props": {
                // This can be a array or objects
                "tab": {
                    "default": "personal-details",
                    // This can be a array or objects
                    "items": ['personal-details', 'notification-preference', 'change-password', 'logout'],
                    // "items": {
                    //     'personal-details': {},
                    //     ...
                    // },
                }
            },
            "subthemes": {
                // "simpleModule": {
                //     "cardModule": {
                //         "props": { // props are avaialble from Paper comp
                //             // "elevation": 2,
                //         },
                //         "sx": (theme) => ({
                //             "root": {
                //                 "padding": theme.modulePadding,
                //                 "borderRadius": theme.moduleBorderRadius,
                //                 [theme.breakpoints.up('lg')]: {
                //                     "marginTop": theme.spacing(0),
                //                 }
                //             },
                //         }),
                //     },
                // },

            },

        },
        "myPropertyPageTenant": {
            "subthemes": {
                "mainContent": { // Remove top content spacing on mobile
                    "sx": (theme) => ({
                        "display": "flex",
                        "flexDirection": "column",
                        "width": "100%",
                        "flexGrow": 1,
                        "padding": theme.spacing(0, 0, (theme.myAccountContentPBmobile + 8), 0),
                        [theme.breakpoints.up('sm')]: {
                            "padding": theme.spacing(0, 0, (theme.myAccountContentPBtablet + 8), 0),
                        },
                        [theme.breakpoints.up('md')]: {
                            "width": `calc(100% - ${theme.myAccountSetDrawerWidth}px)`, // TODO REMOVE THIS WHEN WE USE GLOBALLAYOUT ON PROP DETAILS PAGE; THERE WE DON'T NEED CALC WIDTH AS ITS 100%
                            "padding": theme.spacing(theme.myAccountContentPTtablet, 0, theme.myAccountContentPBtablet, 0),
                        },
                        [theme.breakpoints.up('lg')]: {
                            "padding": theme.spacing(theme.myAccountContentPTDesktop, 0, theme.myAccountContentPBDesktop, 0),
                        },
                        "& .mainContent-wrapper": {
                            "flexGrow": 1,
                        },
                        "& .mainContent-toolbar": theme.mixins.toolbar,
                    }),
                },
                "tabsMyAccount": {
                    "sx": (theme) => ({
                        "flexGrow": 1,
                        "marginBottom": theme.moduleSpacing,
                        [theme.breakpoints.up('sm')]: {
                            "marginBottom": theme.moduleSpacingSm,
                        },
                        [theme.breakpoints.up('lg')]: {
                            "marginBottom": theme.moduleSpacingLg,
                        },
                        // "backgroundColor": theme.palette.background.paper,
                        "& .tabsMyAccount-tabs": {
                            "display": "none",
                            "borderBottom": "1px solid #d8d8d8",
                            "marginLeft": `-${theme.gridItemPmobile}`,
                            "marginRight": `-${theme.gridItemPmobile}`,
                            "marginTop": `-${theme.spacing(4)}`, // we need to have negative margin on mobile to pull the 2 tabs bellow the image carousel
                            [theme.breakpoints.up('sm')]: {
                                "marginLeft": `-${theme.gridItemPtablet}`,
                                "marginRight": `-${theme.gridItemPtablet}`,
                                "paddingLeft": theme.gridItemPtablet,
                                "paddingRight": theme.gridItemPtablet,
                                "marginTop": 0,
                            },
                            [theme.breakpoints.up('md')]: {
                                "display": "flex",
                            },
                            [theme.breakpoints.up('lg')]: {
                                "marginLeft": `calc(-${theme.gridItemPdesktop} * 2)`,
                                "marginRight": `calc(-${theme.gridItemPdesktop} * 2)`,
                                "paddingLeft": `calc(${theme.gridItemPdesktop} * 2)`,
                                "paddingRight": `calc(${theme.gridItemPdesktop} * 2)`,
                            },
                            "& .tabsMyAccount-indicator": {
                                "height": "3px",
                                "backgroundColor": theme.palette.secondary.main,
                            },
                            "& .tabsMyAccount-scrollButtons": {
                                "alignItems": "flex-start",
                                "& svg": {
                                    "marginTop": "12px",
                                },
                                "&.Mui-disabled": {
                                    "opacity": 0.3,
                                },
                            },
                        },
                        "& .tabsMyAccount-tab": {
                            "fontFamily": theme.primaryFontFamily,
                            "textTransform": "none",
                            [theme.breakpoints.up('sm')]: {
                                "minWidth": "auto",
                                "paddingLeft": 0,
                                "paddingRight": 0,
                                "marginRight": theme.spacing(5),
                            },
                            [theme.breakpoints.up('md')]: {
                                "paddingBottom": theme.spacing(3.5),
                            },
                            "&.tabsMyAccount-selected": {
                                "color": theme.palette.common.black,
                            },
                            "& .tabsMyAccount-wrapper": {
                                "fontSize": theme.bodySmall,
                                [theme.breakpoints.up('sm')]: {
                                    "fontSize": theme.bodyLarge,
                                }
                            },
                        },
                        "& .tabsMyAccount-tabPanel": {
                            "padding": 0,
                        },
                        "& .tabsMyAccount-tabPanelBox": {
                            "padding": theme.spacing(0, 0, 0, 0),
                            [theme.breakpoints.up('md')]: {
                                "padding": theme.spacing(7, 0, 0, 0),
                            },
                        }
                    }),
                    "subthemes": {
                        "tabsMyAccount": {
                            "sx": (theme) => ({
                                "flexGrow": 1,
                                "marginBottom": theme.moduleSpacing,
                                [theme.breakpoints.up('sm')]: {
                                    "marginBottom": theme.moduleSpacingSm,
                                },
                                [theme.breakpoints.up('lg')]: {
                                    "marginBottom": theme.moduleSpacingLg,
                                },
                                // "backgroundColor": theme.palette.background.paper,
                                "& .tabsMyAccount-tabs": {
                                    "display": "flex",
                                    "borderBottom": "1px solid #d8d8d8",
                                    "marginLeft": `-${theme.gridItemPmobile}`,
                                    "marginRight": `-${theme.gridItemPmobile}`,
                                    [theme.breakpoints.up('sm')]: {
                                        "marginLeft": `-${theme.gridItemPtablet}`,
                                        "marginRight": `-${theme.gridItemPtablet}`,
                                        "paddingLeft": theme.gridItemPtablet,
                                        "paddingRight": theme.gridItemPtablet,
                                    },
                                    [theme.breakpoints.up('md')]: {
                                        "borderBottom": "none",
                                    },
                                    [theme.breakpoints.up('lg')]: {
                                        "marginLeft": `calc(-${theme.gridItemPdesktop} * 2)`,
                                        "marginRight": `calc(-${theme.gridItemPdesktop} * 2)`,
                                        "paddingLeft": `calc(${theme.gridItemPdesktop} * 2)`,
                                        "paddingRight": `calc(${theme.gridItemPdesktop} * 2)`,
                                    },
                                    "& .tabsMyAccount-indicator": {
                                        "height": "3px",
                                        // "backgroundColor": theme.palette.primary.main,
                                    },
                                    "& .tabsMyAccount-scrollButtons": {
                                        "alignItems": "flex-start",
                                        "& svg": {
                                            "marginTop": "12px",
                                        },
                                        "&.Mui-disabled": {
                                            "opacity": 0.3,
                                        },
                                    },
                                },
                                "& .tabsMyAccount-tab": {
                                    "textTransform": "none",
                                    [theme.breakpoints.up('sm')]: {
                                        "minWidth": "auto",
                                        "paddingLeft": 0,
                                        "paddingRight": 0,
                                        "marginRight": theme.spacing(5),
                                    },
                                    [theme.breakpoints.up('md')]: {
                                        "paddingBottom": theme.spacing(0.75),
                                    },
                                    "&.tabsMyAccount-selected": {
                                        "fontWeight": theme.typography.fontWeightBold,
                                    },
                                    "& .tabsMyAccount-wrapper": {
                                        "fontSize": theme.bodySmall,
                                        [theme.breakpoints.up('sm')]: {
                                            "fontSize": theme.typography.h5.fontSize,
                                        }
                                    },
                                },
                                "& .tabsMyAccount-tabPanel": {
                                    "padding": 0,
                                },
                                "& .tabsMyAccount-tabPanelBox": {
                                    "padding": theme.spacing(2.75, 0, 0, 0),
                                    [theme.breakpoints.up('md')]: {
                                        "padding": theme.spacing(2.75, 0, 0, 0),
                                    },
                                }
                            }),
                        },
                    },
                },
            },
        },
        "myPropertyPageBuyer": {
            "subthemes": {
                "mainContent": { // Remove top content spacing on mobile
                    "sx": (theme) => ({
                        "display": "flex",
                        "flexDirection": "column",
                        "width": "100%",
                        "flexGrow": 1,
                        "padding": theme.spacing(0, 0, (theme.myAccountContentPBmobile + 8), 0),
                        [theme.breakpoints.up('sm')]: {
                            "padding": theme.spacing(0, 0, (theme.myAccountContentPBtablet + 8), 0),
                        },
                        [theme.breakpoints.up('md')]: {
                            "width": `calc(100% - ${theme.myAccountSetDrawerWidth}px)`, // TODO REMOVE THIS WHEN WE USE GLOBALLAYOUT ON PROP DETAILS PAGE; THERE WE DON'T NEED CALC WIDTH AS ITS 100%
                            "padding": theme.spacing(theme.myAccountContentPTtablet, 0, theme.myAccountContentPBtablet, 0),
                        },
                        [theme.breakpoints.up('lg')]: {
                            "padding": theme.spacing(theme.myAccountContentPTDesktop, 0, theme.myAccountContentPBDesktop, 0),
                        },
                        "& .mainContent-wrapper": {
                            "flexGrow": 1,
                        },
                        "& .mainContent-toolbar": theme.mixins.toolbar,
                    }),
                },
                "tabsMyAccount": {
                    "sx": (theme) => ({
                        "flexGrow": 1,
                        "marginBottom": theme.moduleSpacing,
                        [theme.breakpoints.up('sm')]: {
                            "marginBottom": theme.moduleSpacingSm,
                        },
                        [theme.breakpoints.up('lg')]: {
                            "marginBottom": theme.moduleSpacingLg,
                        },
                        // "backgroundColor": theme.palette.background.paper,
                        "& .tabsMyAccount-tabs": {
                            "display": "none",
                            "borderBottom": "1px solid #d8d8d8",
                            "marginLeft": `-${theme.gridItemPmobile}`,
                            "marginRight": `-${theme.gridItemPmobile}`,
                            "marginTop": `-${theme.spacing(4)}`, // we need to have negative margin on mobile to pull the 2 tabs bellow the image carousel
                            [theme.breakpoints.up('sm')]: {
                                "marginLeft": `-${theme.gridItemPtablet}`,
                                "marginRight": `-${theme.gridItemPtablet}`,
                                "paddingLeft": theme.gridItemPtablet,
                                "paddingRight": theme.gridItemPtablet,
                                "marginTop": 0,
                            },
                            [theme.breakpoints.up('md')]: {
                                "display": "flex",
                            },
                            [theme.breakpoints.up('lg')]: {
                                "marginLeft": `calc(-${theme.gridItemPdesktop} * 2)`,
                                "marginRight": `calc(-${theme.gridItemPdesktop} * 2)`,
                                "paddingLeft": `calc(${theme.gridItemPdesktop} * 2)`,
                                "paddingRight": `calc(${theme.gridItemPdesktop} * 2)`,
                            },
                            "& .tabsMyAccount-indicator": {
                                "height": "3px",
                                "backgroundColor": theme.palette.secondary.main,
                            },
                            "& .tabsMyAccount-scrollButtons": {
                                "alignItems": "flex-start",
                                "& svg": {
                                    "marginTop": "12px",
                                },
                                "&.Mui-disabled": {
                                    "opacity": 0.3,
                                },
                            },
                        },
                        "& .tabsMyAccount-tab": {
                            "fontFamily": theme.primaryFontFamily,
                            "textTransform": "none",
                            [theme.breakpoints.up('sm')]: {
                                "minWidth": "auto",
                                "paddingLeft": 0,
                                "paddingRight": 0,
                                "marginRight": theme.spacing(5),
                            },
                            [theme.breakpoints.up('md')]: {
                                "paddingBottom": theme.spacing(3.5),
                            },
                            "&.tabsMyAccount-selected": {
                                "color": theme.palette.common.black,
                            },
                            "& .tabsMyAccount-wrapper": {
                                "fontSize": theme.bodySmall,
                                [theme.breakpoints.up('sm')]: {
                                    "fontSize": theme.bodyLarge,
                                }
                            },
                        },
                        "& .tabsMyAccount-tabPanel": {
                            "padding": 0,
                        },
                        "& .tabsMyAccount-tabPanelBox": {
                            "padding": theme.spacing(0, 0, 0, 0),
                            [theme.breakpoints.up('md')]: {
                                "padding": theme.spacing(7, 0, 0, 0),
                            },
                        }
                    }),
                    "subthemes": {
                        "tabsMyAccount": {
                            "sx": (theme) => ({
                                "flexGrow": 1,
                                "marginBottom": theme.moduleSpacing,
                                [theme.breakpoints.up('sm')]: {
                                    "marginBottom": theme.moduleSpacingSm,
                                },
                                [theme.breakpoints.up('lg')]: {
                                    "marginBottom": theme.moduleSpacingLg,
                                },
                                // "backgroundColor": theme.palette.background.paper,
                                "& .tabsMyAccount-tabs": {
                                    "display": "flex",
                                    "borderBottom": "1px solid #d8d8d8",
                                    "marginLeft": `-${theme.gridItemPmobile}`,
                                    "marginRight": `-${theme.gridItemPmobile}`,
                                    [theme.breakpoints.up('sm')]: {
                                        "marginLeft": `-${theme.gridItemPtablet}`,
                                        "marginRight": `-${theme.gridItemPtablet}`,
                                        "paddingLeft": theme.gridItemPtablet,
                                        "paddingRight": theme.gridItemPtablet,
                                    },
                                    [theme.breakpoints.up('md')]: {
                                        "borderBottom": "none",
                                    },
                                    [theme.breakpoints.up('lg')]: {
                                        "marginLeft": `calc(-${theme.gridItemPdesktop} * 2)`,
                                        "marginRight": `calc(-${theme.gridItemPdesktop} * 2)`,
                                        "paddingLeft": `calc(${theme.gridItemPdesktop} * 2)`,
                                        "paddingRight": `calc(${theme.gridItemPdesktop} * 2)`,
                                    },
                                    "& .tabsMyAccount-indicator": {
                                        "height": "3px",
                                        // "backgroundColor": theme.palette.primary.main,
                                    },
                                    "& .tabsMyAccount-scrollButtons": {
                                        "alignItems": "flex-start",
                                        "& svg": {
                                            "marginTop": "12px",
                                        },
                                        "&.Mui-disabled": {
                                            "opacity": 0.3,
                                        },
                                    },
                                },
                                "& .tabsMyAccount-tab": {
                                    "textTransform": "none",
                                    [theme.breakpoints.up('sm')]: {
                                        "minWidth": "auto",
                                        "paddingLeft": 0,
                                        "paddingRight": 0,
                                        "marginRight": theme.spacing(5),
                                    },
                                    [theme.breakpoints.up('md')]: {
                                        "paddingBottom": theme.spacing(0.75),
                                    },
                                    "&.tabsMyAccount-selected": {
                                        "fontWeight": theme.typography.fontWeightBold,
                                    },
                                    "& .tabsMyAccount-wrapper": {
                                        "fontSize": theme.bodySmall,
                                        [theme.breakpoints.up('sm')]: {
                                            "fontSize": theme.typography.h5.fontSize,
                                        }
                                    },
                                },
                                "& .tabsMyAccount-tabPanel": {
                                    "padding": 0,
                                },
                                "& .tabsMyAccount-tabPanelBox": {
                                    "padding": theme.spacing(2.75, 0, 0, 0),
                                    [theme.breakpoints.up('md')]: {
                                        "padding": theme.spacing(2.75, 0, 0, 0),
                                    },
                                }
                            }),
                        },
                    },
                },
            },
        },
        "propertyResultsPageFilters": { // options: src/components/MyAccount/pages/properties/defaults.js
        },
        "propertyResultsPage": {
            "subthemes": {
                "results": { // default property result styling
                    "sx": (theme) => ({
                        [theme.breakpoints.up('sm')]: {
                            "width": "auto",
                            "margin": theme.spacing(0, -0.75),
                        },
                        [theme.breakpoints.up('lg')]: {
                            "margin": theme.spacing(0, -2.375),
                        },
                    }),
                    "subthemes": {
                        "propertyresult": {
                            "props": {
                                "container": {
                                    "order": ['media', 'header', 'aside'],
                                    "sx": (theme) => ({
                                        "flexDirection": "column",
                                        "width": "100%",
                                        "marginBottom": theme.spacing(3),
                                        [theme.breakpoints.up('sm')]: {
                                            "width": `calc(50% - ${theme.spacing(1.5)})`,
                                            "marginBottom": theme.spacing(5),
                                            "marginLeft": theme.spacing(0.75),
                                            "marginRight": theme.spacing(0.75),
                                        },
                                        [theme.breakpoints.up('lg')]: {
                                            "width": `calc(33.33% - ${theme.spacing(4.75)})`,
                                            "marginLeft": theme.spacing(2.375),
                                            "marginRight": theme.spacing(2.375),
                                        },
                                    }),
                                },
                                "header": {
                                    "sx": (theme) => ({
                                        "width": "100%",
                                        "marginTop": theme.spacing(1.5),
                                        [theme.breakpoints.up('sm')]: {
                                            "marginTop": theme.spacing(3.25),
                                        },
                                        "& .propertyresult-address": {
                                            "fontWeight": "bold",
                                            [theme.breakpoints.up('sm')]: {
                                                "fontSize": theme.bodyLarge,
                                                "lineHeight": theme.lineHeightBLarge,
                                                "marginBottom": theme.spacing(0.25),
                                            },
                                        },
                                        "& .propertyresult-rooms": {

                                            // "paddingBottom": theme.spacing(1.25),
                                        },
                                    }),
                                },
                                "aside": {
                                    "sx": (theme) => ({
                                        "width": "100%",
                                        "display": "flex",
                                        "flexDirection": "row",
                                        "alignItems": "center",
                                        "justifyContent": "flex-end",
                                        "& .propertyresult-price": {
                                            "marginRight": "auto",
                                            [theme.breakpoints.up('sm')]: {
                                                "fontSize": theme.bodyMedium,
                                                "lineHeight": theme.lineHeightBMedium,
                                            },
                                        },
                                    }),
                                },
                                "price": {
                                    "in": "aside",
                                    "variant": "bodySmall",
                                    "component": "p",
                                },
                                "address": {
                                    "in": "header",
                                    "variant": "bodyMedium",
                                    "component": "header",
                                },
                                "offer": {
                                    "in": "header",
                                    "variant": "bodyMedium",
                                },
                                "rooms": {
                                    "in": "header",
                                },
                                "bookviewing": {
                                    "in": "aside",
                                },
                                "save": {
                                    "in": "media",
                                },
                                "360tour": {
                                    "in": "media"
                                },
                                // "removeProperty": {
                                //     "in": "aside",
                                // },
                                "media": {
                                    "sx": (theme) => ({
                                        "position": "relative",
                                        "overflow": "hidden",
                                        "& .propertyresult-link-image": {
                                            "display": "block",
                                            "&:after": {
                                                "content": "''",
                                                "position": "absolute",
                                                "top": 0,
                                                "left": 0,
                                                "width": "100%",
                                                "height": "100%",
                                                "background": "rgba(0,0,0,0.2)",
                                                "zIndex": "2",
                                                "pointerEvents": "none",
                                            },
                                        },
                                        "& .propertyresult-image": {
                                            "display": "block",
                                            "width": "100%",
                                        },
                                        "& .propertyresult-save": {
                                            "position": "absolute",
                                            "top": "10px",
                                            "right": "10px",
                                            "zIndex": "20",
                                            "& svg": {
                                                "color": "#fff",
                                            },
                                        },
                                        "& .propertyresult-360tour": {
                                            "position": "absolute",
                                            "bottom": "15px",
                                            "right": "15px",
                                            "width": "auto",
                                            "zIndex": 20,
                                        },
                                    })
                                },
                            },

                            // "typography": {
                            //     "h6": { "textTransform": "none" },
                            //     "subtitle1": { "variant": "bodyMedium", "textTransform": "none" },
                            //     "subtitle2": { "textTransform": "none" },
                            // },

                            "addressFormat": {
                                "separator": " , ",
                                "values": (p) => [p.display_address, p.street_name, p.district, p.area].filter(x => x).shift()
                                // "values": (p) => console.log(p)
                            },
                            "roomsFormat": {
                                "separator": " ",
                                "values": (p) => [p.bedrooms, p.receptions, p.bathrooms]
                            },
                            "linkFormat": {
                                "separator": "-",
                                "target": "_blank",
                                // "values": (p) => propertyLink(p)
                                // "values": (p) => console.log(p)
                            },
                            // "lettingsPricePrefix": "",
                            "lettingsPriceSuffix": " PCM",
                            // "salesPricePrefix": "",
                            // "salesPriceSuffix": "",
                        },
                    },
                },
                "propertyAreaInfo": {},
                "propertyDetailsRooms": {
                    "sx": (theme) => ({
                        "display": "flex",
                        "flexDirection": "row",
                        "& .roomsItem": {
                            "display": "flex",
                            "flexDirection": "row",
                            "marginRight": theme.spacing(2.25),
                            [theme.breakpoints.up('sm')]: {
                                "marginRight": theme.spacing(3.25),
                            },
                            "& svg": {
                                "marginRight": theme.spacing(1),
                                "color": theme.palette.secondary.main,
                            },
                            "& .roomsItem-text": {

                            },
                        },
                    }),
                },
                "bookViewingBtn": {
                    "props": {
                        "btnLabelProps": "Book viewing online",
                        "btnProps": {
                            "color": "primary",
                            "variant": "text",
                            "size": "small",
                        }
                    }
                },
            },
        },
        "propertyResultsMapPage": {
        },
        "carousel": { // options: src/components/MyAccount/carousel/defaults.js
        },
        "propertyAreaInfo": { // options: src/components/MyAccount/property/components/areaInfo/defaults.js
        },
        "propertyDetailsRooms": { // options: src/components/MyAccount/property/components/rooms/defaults.js
        },
        "locratingIframe": { // options: src/components/MyAccount/property/components/map/defaultsLocratingMap.js
        },
        "videoTour": { // options: src/components/MyAccount/property/components/videoTour/defaults.js
        },
        "virtualTour": { // options: src/components/MyAccount/property/components/virtualTour/defaults.js
        },
        "performanceTabs": { // options: src/components/MyAccount/pages/my-property/common/performance/defaults.js
        },
        "facebookPerformance": { // options: src/components/MyAccount/pages/my-property/common/performance/facebook/defaults.js
        },
        "performanceChart": { // options: src/components/MyAccount/pages/my-property/common/performance/performanceChart/defaults.js
        },
        "lineChart": { // options: src/components/MyAccount/charts/lineChart/defaults.js
        },
        "propertyDetailsPage": {
            "subthemes": {
                "propertyDetailsRooms": {
                    "props": {
                        "propertyBedsText": "Bedrooms",
                        "propertyBedsSingleText": "Bedroom",
                        "propertyBathsText": "Bathrooms",
                        "propertyBathsSingleText": "Bathroom",
                        "propertyRecText": "Receptions",
                        "propertyRecSingleText": "Reception",
                    },
                    "sx": (theme) => ({
                        "marginBottom": theme.spacing(2.25),
                        "display": "flex",
                        "flexDirection": "column",
                        [theme.breakpoints.up('sm')]: {
                            "flexDirection": "row",
                        },
                        "& .roomsItem": {
                            "display": "flex",
                            "flexDirection": "row",
                            [theme.breakpoints.up('sm')]: {
                                "marginRight": theme.spacing(3.25),
                            },
                            "& svg": {
                                "marginRight": theme.spacing(1),
                            },
                        },
                    }),
                },
            }
        },
        "newsOverviewItem": {
        },
        "newsOverviewPage": {
        },
        "newsDetailsPage": { // options: src/components/MyAccount/blog/details/defaults.js
        },
        "404Page": { // options: src/components/MyAccount/404page/defaults.js
        },
        "myaccountFooter": { // options: src/components/MyAccount/footer/defaults.js
            "props": {
                "footerMenus": [
                    {
                        "id": "link1",
                        "name": "Terms of Use",
                        "url": termsSiteLink,
                        "target": "_blank",
                    },
                    {
                        "id": "link2",
                        "name": "Privacy Policy & Notice",
                        "url": privacySiteLink,
                        "target": "_blank",
                    }
                ],
            }
        },
        // },
        // },
    },
}
